import React from 'react';
import PropTypes from "prop-types"
import {browserHistory, Link} from "react-router";
import {Button, Col, Glyphicon, MenuItem, Nav, NavDropdown, NavItem, Panel, Row} from "react-bootstrap";
import {connect} from "react-redux";
import * as proposalActions from "./ProposalMenuApi";
import {bindActionCreators} from "redux";
import {LinkContainer} from "react-router-bootstrap";
import {convertMillisToTime, hrefActiveClass, select} from "../../../common/commonHandlers";
import Select from "react-select";
import ResourceComponent from "../../../components/ResourceComponent";
import './ProposalMenu.css'
import * as dockActions from "../../../components/dock/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InlineEditable from "../../Scheduler/InlineEditable";
import Dollars from "../../../components/Dollars";
import ReactDock from "react-dock";
import VersionHistoryModal from "./VersionHistoryModal/VersionHistoryModal";
import InactiveModal from "./InactiveModal";
import _ from "lodash";

const actions = {
    ...proposalActions,
    ...dockActions,
};

const isMobile = window.screen.width <= 1024;

class ProposalMenu extends ResourceComponent {
    state = {
        showInactiveModal: false
    }

    handleProposalIdChange = done => e => {
        let {resource} = this.state;
        resource.id = e.value;
        resource.lead_id = e.lead_id;
        done();
        this.setState(resource, () => {
            if (e.value) {
                if (window.location.href.includes('/invoices')) {
                    browserHistory.push(`/proposals/${e.value}/invoices`);
                    this.props.onReload(e.value);
                } else if (window.location.href.includes('/mapview')) {
                    browserHistory.push(`/mapview/${e.value}`);
                    this.props.onReload(e.value);
                } else if (window.location.href.includes('/proposal_lead')) {
                    if (e.lead_id) {
                        this.setState(resource, () => {
                            browserHistory.push(`/proposal_lead/${e.lead_id}`);
                            this.props.onReload(e.lead_id);
                        })

                    } else {
                        browserHistory.push(`/mapview/${e.value}`);
                        this.props.onReload(e.lead_id, e.value);
                    }
                } else if (window.location.href.includes('/proposal_service_status')) {
                    browserHistory.push(`/proposal_service_status/${e.value}`);
                    this.props.onReload(e.value);
                } else if (window.location.href.includes('/work_orders')) {
                    browserHistory.push(`/work_orders/${e.value}`);
                    this.props.onReload(e.value);
                } else if (window.location.href.includes('/print_proposal')) {
                    browserHistory.push(`/print_proposal/${e.value}`);
                    this.props.onReload(e.value);
                } else {
                    this.props.onReload(e.value);
                }
            }
        })
    };

    duplicateProposal = (e, id) => {
        if (this.props.customer?.inactive_customer || this.props.customer?.inactive_site) {
            this.setState({showInactiveModal: true})
        } else {
            this.confirmDuplicate(e, id)
        }
    }

    confirmDuplicate = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        let button = document.getElementById('duplicate-button');
        if (!button.classList.contains('disabled')) {
            button.classList.add('disabled');
            this.props.actions.duplicateProposal(id, result => {
                browserHistory.push(`/mapview/${result.id}`);
            })
        }
    };

    renderActions = () => {
        const {downloading, proposalMenu} = this.state;
        const {id, classicView, moveToProposed, customer} = this.props;
        let assets;
        const openVHModal = () => {
            this.setState({isVHModalOpened: true})
        }
        if (this.props.assets && this.props.assets.length) {
            assets = this.props.assets.reduce((callback, val, i) => {
                if (!Array.isArray(callback) || !callback.filter((element) => element.label === val.label)[0]) {
                    if (i === 1) {
                        return [callback, val]
                    } else {
                        return [...callback, val]
                    }
                } else {
                    return callback
                }
            })
        }
        return (this.props && <div className={this.props.theme === 'slate' ? 'actions-info slate' : 'actions-info'}>
            <Row>
                <Col xs={12}>
                    <ul className='dropdown no-margin no-padding justify-flex-start'>
                        <Link
                            onClick={() => this.props.actions.showJSADialog({
                                siteId: proposalMenu && proposalMenu.site_id,
                                ignoreAddMessage: true
                            })}
                            className={id ? '' : 'disabled'}>JSA</Link>
                        <Link
                            onClick={() => {
                                if (!downloading) {
                                    this.setState({downloading: true});
                                    this.props.actions.downloadPdf(proposalMenu && proposalMenu,
                                        () => {
                                            this.setState({downloading: false})
                                        },
                                        () => {
                                            this.setState({downloading: false})
                                        })
                                }
                            }}
                            className={`${id ? '' : 'disabled'}`}>PDF</Link>
                        <Link to={id ? `/proposal_cost/${id}` : `/proposal_cost`}
                              className={`${hrefActiveClass('/proposal_cost')} ${id ? '' : 'disabled'}`}>
                            Job Costs
                        </Link>
                        <Link to={proposalMenu && `/print_proposal/${proposalMenu.id}`}
                              className={hrefActiveClass('/print_proposal')}>
                            Print
                        </Link>
                        <a onClick={() => openVHModal()}>
                            Version History
                        </a>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {id && <NavItem className={`follow-up`}>
                        <Button
                            onClick={(e) => this.duplicateProposal(e, proposalMenu.id)}
                            bsStyle="warning"
                            bsSize="xsmall"
                            id='duplicate-button'
                        >
                            Duplicate Proposal
                        </Button>
                    </NavItem>}
                    <li className="pointer follow-up">
                        {assets && assets.length && customer && this.props.roles.find(role => role === "admin")
                            ? <Link
                                onClick={() => this.props.actions.downloadCsv(this.props.customer.site_id)}
                            >
                                Download Assets
                            </Link>
                            : null
                        }
                        {this.props.toggleNewAssets && <Link onClick={() => this.props.toggleNewAssets(id)}>
                            Toggle assets
                        </Link>}
                        {moveToProposed &&
                            <Link className={!moveToProposed ? `disabled` : ``}
                                  onClick={() => this.props.actions.moveToProposed(proposalMenu.id)}>
                                Move to proposed</Link>
                        }
                    </li>
                    {classicView &&
                        <Link to={`/mapview_old/${customer.id}`}>
                            Classic View
                        </Link>}
                    <Link onClick={this.showDock} className={id ? '' : 'disabled'}>
                        Show Proposal Cost Info
                    </Link>
                    {this.props.roles.find(role => role === "admin") && <Link onClick={this.props.exportToKMZ} className={id ? '' : 'disabled'}>
                        Export to KMZ
                    </Link>}
                </Col>
            </Row>
        </div>)
    }

    renderCustomerInfo = (proposalInfo) => {
        return (<div className={this.props.theme === 'slate' ? 'customer-info slate' : 'customer-info'}>
                <Row>
                    <Col xs={12}>
                        <h5 className="text-success">Customer Links</h5>
                    </Col>
                    <Col xs={12}>
                        <ul className='dropdown no-margin no-padding justify-flex-start'>
                            <LinkContainer to={`/customer/info/${proposalInfo.customerId}`}>
                                <MenuItem>Info</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/proposals/${proposalInfo.customerId}`}>
                                <MenuItem bsSize="small">Proposals</MenuItem>
                            </LinkContainer>
                            <LinkContainer
                                to={`/customer/sites/${proposalInfo.customerId}?site_id=${proposalInfo.site_id}`}>
                                <MenuItem bsSize="small">Sites</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/work_orders/${proposalInfo.customerId}`}>
                                <MenuItem bsSize="small">Work Orders</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/invoices/${proposalInfo.customerId}`}>
                                <MenuItem bsSize="small">Invoices</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/customer/work_history/${proposalInfo.customerId}`}>
                                <MenuItem>Work History</MenuItem>
                            </LinkContainer>
                        </ul>
                    </Col>
                </Row>
                <Row className="word-wrap">
                    <Col md={3}>
                        <h5 className="text-success">Bill To</h5>
                        <div>{proposalInfo.customerName}</div>
                        <div>
                            {proposalInfo.customerAddress1}<br/>
                            {proposalInfo.customerAddress2 ? proposalInfo.customerAddress2 : null}
                            {proposalInfo.customerAddress2 ? <br/> : null}
                            {proposalInfo.customerCity}, {proposalInfo.customerState}<br/>
                            {proposalInfo.customerZip}
                        </div>
                    </Col>

                    <Col md={3}>
                        <h5 className="text-success">Site Contact</h5>
                        {proposalInfo.siteName}
                        {proposalInfo.siteName ? <br/> : null}

                        {isMobile ?
                            <>{proposalInfo.contactPhone && <a className="contact-phone"
                                                               href={"tel:" + proposalInfo.contactPhone.replace(/\D/g, '')}>
                                {proposalInfo.contactPhone}</a>}</>
                            : <>{proposalInfo.contactPhone}</>
                        }
                        {proposalInfo.contactPhone ? <br/> : null}

                        {proposalInfo.contactEmail}
                    </Col>

                    <Col md={3}>
                        <h5 className="text-success">Site Information</h5>
                        {proposalInfo.siteName}<br/>
                        {`${proposalInfo.siteAddress1} ${proposalInfo.siteCity} ${proposalInfo.siteZip}`}
                    </Col>

                    <Col md={3}>
                        <h5 className="text-success">Sales Arborist</h5>
                        {proposalInfo.salesArboristName}
                    </Col>

                </Row>
            </div>
        )
    };
    showDock = () => {
        this.setState({showDock: true})
    };


    componentWillReceiveProps(nextProps) {
        const {id} = nextProps;
        if (this.props.id !== id) {
            let button = document.getElementById('duplicate-button');
            if (button && button.classList.contains('disabled')) {
                button.classList.remove('disabled');
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.id !== this.props.id || prevProps.reloadKey !== this.props.reloadKey) && this.props.id) {
            this.props.actions.load(this.props.id, result => {
                this.setState(result)
            });
        }

        if ((prevProps.id !== this.props.id || prevProps.reloadKey !== this.props.reloadKey) && !this.props.id) {
            const resetState = {
                proposals: [],
                proposalMenu: null,
                time_track_types: null
            }
            this.setState(resetState)
        }
    }

    componentDidMount() {
        const {id} = this.props;
        this.proposalSearch = this.buildSearchComponent('proposals', this.props.actions.searchForProposal);
        if (id) {
            this.props.actions.load(id, result => this.setState(result));
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            downloading: false,
            proposals: [],
            resource: {},
            proposalMenu: null,
            time_track_types: null,
            showDock: null,
            isVHModalOpened: false
        };
        this.proposalSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
    }

    render() {
        const {proposalMenu, time_track_types, showDock} = this.state;
        const {id, email, isPanelVisible, expandButton, onShowFilter} = this.props;
        const proposalsOptions = this.proposalSearch.searchOptions();
        const hasLead = proposalMenu && proposalMenu.proposalInfo && proposalMenu.proposalInfo.lead_id;
        if (!proposalMenu && this.props) {
            return <div><Row className="top-menu loading top-25"><Col md={7}>
                {window.innerWidth > 990 ? <div className={`inline-block`}>
                        <div className="inline-select"><InlineEditable
                            value={
                                <a>{`Proposal #`}</a>}
                            withSubmit={false}
                            form={(done) =>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        onInputChange={this.proposalSearch.search}
                                        value={select(proposalsOptions, id)}
                                        options={proposalsOptions}
                                        placeholder="Proposal #"
                                        onMenuClose={done}
                                        onChange={this.handleProposalIdChange(done)}
                                />
                            }
                        /></div>
                        <Link className={`disabled`}>
                            Customer
                        </Link>
                        <Link
                            onClick={() => {
                                browserHistory.push(hasLead ? `/proposal_lead/${proposalMenu.proposalInfo.lead_id}` : `/lead`);
                            }}
                            className={hasLead ? (hrefActiveClass('/proposal_lead')) : hrefActiveClass('/lead')}>
                            Lead
                        </Link>
                        <Link onClick={() => browserHistory.push(`/mapview`)}
                              className={hrefActiveClass('/mapview')}>
                            Edit
                        </Link>
                        <Link onClick={() => browserHistory.push(`/proposal_service_status`)}
                              className={hrefActiveClass('/proposal_service_status')}>
                            Status
                        </Link>
                        <Link onClick={() => browserHistory.push(`/work_orders`)}
                              className={hrefActiveClass('/work_orders')}>
                            WOs
                        </Link>
                        <Link className={`disabled`}>
                            Complete
                        </Link>
                        <Link className={`disabled`}>
                            Invoices
                        </Link>
                    </div>
                    : <div>
                        <div className="inline-select"><InlineEditable
                            value={
                                <a>{`Proposal #`}</a>}
                            withSubmit={false}
                            form={(done) =>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        onInputChange={this.proposalSearch.search}
                                        value={select(proposalsOptions, id)}
                                        options={proposalsOptions}
                                        placeholder="Proposal #"
                                        onMenuClose={done}
                                        onChange={this.handleProposalIdChange(done)}
                                />
                            }
                        /></div>
                        <NavDropdown noCaret title='Menu' className="mr-auto inline-block">
                            <NavItem
                                onClick={() => {
                                    if (hasLead) {
                                        browserHistory.push(`/proposal_lead/${proposalMenu.proposalInfo.lead_id}`);
                                    }
                                }}
                                className={hasLead ? (hrefActiveClass('/proposal_lead')) : `disabled`}>
                                Lead
                            </NavItem>
                            <NavItem onClick={() => browserHistory.push(`/mapview`)}
                                     className={hrefActiveClass('/mapview')}>
                                Edit
                            </NavItem>
                            <NavItem onClick={() => browserHistory.push(`/proposal_service_status`)}
                                     className={hrefActiveClass('/proposal_service_status')}>
                                Status
                            </NavItem>
                            <NavItem onClick={() => browserHistory.push(`/work_orders`)}
                                     className={hrefActiveClass('/work_orders')}>
                                WOs
                            </NavItem>
                            <NavItem className={`disabled`}>
                                Complete
                            </NavItem>
                            <NavItem className={`disabled`}>
                                Invoices
                            </NavItem>
                        </NavDropdown></div>}
            </Col>
                <Col md={5} className={window.innerWidth > 900 && `top18`}>
                    <Link className={`disabled`}>
                        Email
                    </Link>
                    <Link className='actions-link'>
                        <span>Actions</span>
                    </Link>
                    {this.renderActions(this.openVHModal)}
                    <Link className={`disabled`}>
                        Notes
                    </Link>
                </Col>
            </Row>
                <Panel
                    collapsible
                    expanded={false}
                    className="bottom0"
                />
            </div>
        }
        let actual_seconds = proposalMenu && proposalMenu.time_tracks.length
            && proposalMenu.time_tracks.map(item => item.total_seconds).reduce((prev, next) => prev + next);
        let nonBillTime = [];
        let billTime = [];
        time_track_types && time_track_types.length && time_track_types.forEach(track => {
            if (track.label !== 'Billable') {
                let type = {};
                type.name = track.label;
                type.value = proposalMenu.time_tracks.filter(t => t.time_track_type_id === track.value)
                    .map(item => item.total_seconds || 0)
                    .reduce((prev, next) => prev + next, 0);
                nonBillTime.push(type);
            } else {
                billTime.push({
                    name: 'Work',
                    value: proposalMenu.time_tracks
                        .filter(t => t.time_track_type_id === track.value)
                        .map(item => item.total_seconds || 0)
                        .reduce((prev, next) => prev + next, 0)
                });
            }
        });


        let recipientEmail;

        const service_price = proposalMenu && proposalMenu.wos.length > 0 && proposalMenu.wos.map(wo => wo.service_price).reduce((partial_sum, a) => partial_sum + a)
        const service_hours = proposalMenu && proposalMenu.wos.length > 0 && proposalMenu.wos.map(wo => wo.service_hours).reduce((partial_sum, a) => partial_sum + a)

        const {
            contactEmail,
            contactEmail2,
            secondContactEmail,
            secondContactEmail2,
            additionalContactEmails,
            additionalContactEmails2
        } = proposalMenu.proposalInfo
        const primarySiteContactEmails = (contactEmail && contactEmail2) ? `${contactEmail}, ${contactEmail2}` : contactEmail
        const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

        recipientEmail = `${primarySiteContactEmails || ""}`
        if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`
        if (additionalContactEmails?.length > 0) recipientEmail += `, ${additionalContactEmails.toString()}`
        if (additionalContactEmails2?.length > 0) recipientEmail += `, ${additionalContactEmails2.toString()}`

        const groupedTimeTracks = proposalMenu && _.groupBy(proposalMenu.time_tracks, 'work_order_id');
        const proposalWorkOrders = proposalMenu && proposalMenu.wos.map(w => {
            const timeTracks = groupedTimeTracks[w.work_order_id];

            let actual_seconds = timeTracks?.map(item => item.total_seconds).reduce((prev, next) => prev + next) || 0;
            let nonBillTime = [];
            let billTime = [];
            time_track_types && time_track_types.length && time_track_types.forEach(track => {
                if (track.label !== 'Billable') {
                    let type = {};
                    type.name = track.label;
                    type.value = timeTracks
                        ?.filter(t => t.time_track_type_id === track.value)
                        .map(item => item.total_seconds || 0)
                        .reduce((prev, next) => prev + next, 0) || 0;
                    nonBillTime.push(type);
                } else {
                    billTime.push({
                        name: 'Work',
                        value: timeTracks
                            ?.filter(t => t.time_track_type_id === track.value)
                            .map(item => item.total_seconds || 0)
                            .reduce((prev, next) => prev + next, 0) || 0
                    });
                }
            });

            return {
                ...w,
                actual_seconds,
                nonBillTime,
                billTime
            }
        });

        return (
            <div>
                {proposalMenu && <Row className="top-menu top-25">
                    <Col md={7}>
                        {expandButton &&
                            <Button bsStyle="success" bsSize="small" onClick={this.props.handleHidePanel}><Glyphicon
                                glyph={isPanelVisible ? 'minus' : 'plus'}
                                className="small"/></Button>}
                        <div className="inline-select"><InlineEditable
                            value={
                                <a>{proposalMenu.proposal_no ? `Proposal #${proposalMenu.proposal_no}` : `Proposal #`}</a>}
                            withSubmit={false}
                            form={(done) =>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        onInputChange={this.proposalSearch.search}
                                        value={select(proposalsOptions, id)}
                                        options={proposalsOptions}
                                        placeholder="Proposal #"
                                        onMenuClose={done}
                                        isDisabled={window.location.href.includes('/print_work_orders') || window.location.href.includes('/new_invoice_print')}
                                        onChange={this.handleProposalIdChange(done)}
                                />
                            }
                        /></div>
                        <Link className='customer-link'>
                            <span>{proposalMenu.proposalInfo && proposalMenu.proposalInfo.contactName}</span>
                        </Link>
                        {proposalMenu && proposalMenu.proposalInfo && this.renderCustomerInfo(proposalMenu.proposalInfo)}

                        {window.innerWidth > 990 ? <div className={`inline-block`}>
                                <Link
                                    onClick={() => {
                                        if (hasLead) {
                                            browserHistory.push(`/proposal_lead/${proposalMenu.proposalInfo.lead_id}`);
                                        }
                                    }}
                                    className={hasLead ? (hrefActiveClass('/proposal_lead')) : `disabled`}>
                                    Lead
                                </Link>
                                <Link onClick={() => browserHistory.push(`/mapview/${id}`)}
                                      className={hrefActiveClass('/mapview')}>
                                    Edit
                                </Link>
                                <Link onClick={() => browserHistory.push(`/proposal_service_status/${id}`)}
                                      className={hrefActiveClass('/proposal_service_status')}>
                                    Status
                                </Link>
                                <Link onClick={() => browserHistory.push(`/work_orders/${id}`)}
                                      className={hrefActiveClass('/work_orders')}>
                                    WOs
                                </Link>
                                <span><Nav><NavDropdown
                                    noCaret
                                    eventKey={2}
                                    title='Complete'
                                    className={`menu-show-fixer proposal-menu-padding-reset ${proposalMenu.wos.length > 0 ? (hrefActiveClass('/complete_work_orders')) : 'disabled'}`}
                                >
                            {proposalMenu.wos.length > 0 && proposalMenu.wos.sort((a, b) => a.work_order_no - b.work_order_no).map((wo, index) =>
                                <li key={index}
                                    className='pointer'>
                                    <Link
                                        onClick={() => browserHistory.push(`/complete_work_orders/${wo.work_order_no}`)}
                                    >WO #{wo.work_order_no}</Link></li>)}

                        </NavDropdown></Nav></span>
                                <Link onClick={() => browserHistory.push(`/proposals/${id}/invoices`)}
                                      className={hrefActiveClass('/proposals') && hrefActiveClass('/invoices')}>
                                    Invoices
                                </Link>
                            </div>
                            :
                            <NavDropdown noCaret title='Menu' className="mr-auto inline-block">
                                <NavItem
                                    onClick={() => {
                                        if (hasLead) {
                                            browserHistory.push(`/proposal_lead/${proposalMenu.proposalInfo.lead_id}`);
                                        }
                                    }}
                                    className={hasLead ? (hrefActiveClass('/proposal_lead')) : `disabled`}>
                                    Lead
                                </NavItem>
                                <NavItem onClick={() => browserHistory.push(`/mapview/${id}`)}
                                         className={hrefActiveClass('/mapview')}>
                                    Edit
                                </NavItem>
                                <NavItem onClick={() => browserHistory.push(`/proposal_service_status/${id}`)}
                                         className={hrefActiveClass('/proposal_service_status')}>
                                    Status
                                </NavItem>
                                <NavItem onClick={() => browserHistory.push(`/work_orders/${id}`)}
                                         className={hrefActiveClass('/work_orders')}>
                                    WOs
                                </NavItem>
                                <NavItem onClick={() => browserHistory.push(`/proposals/${id}/invoices`)}
                                         className={hrefActiveClass('/proposals') && hrefActiveClass('/invoices')}>
                                    Invoices
                                </NavItem>
                                <NavDropdown
                                    noCaret
                                    eventKey={2}
                                    title='Complete'
                                    className={`menu-show-fixer proposal-menu-padding-reset ${proposalMenu.wos.length > 0 ? (hrefActiveClass('/complete_work_orders')) : 'disabled'}`}
                                >
                                    {proposalMenu.wos.length > 0 && proposalMenu.wos.map((wo, index) => <li key={index}
                                                                                                            className='pointer'>
                                        <Link
                                            onClick={() => browserHistory.push(`/complete_work_orders/${wo.work_order_no}`)}
                                        >WO #{wo.work_order_no}</Link></li>)}

                                </NavDropdown>
                            </NavDropdown>}
                    </Col>
                    <Col md={5} className={window.innerWidth > 900 && `top18`}>
                        <Link onClick={() => this.props.actions.showEmailDialog({
                            emailType: "proposal",
                            referenceId: id,
                            recipient: recipientEmail,
                            defaultEmail: email,
                            handleAction: () => this.props.onEmailSend && this.props.onEmailSend(id)
                        })}
                              className={id ? '' : 'disabled'}>Email</Link>

                        <Link className='actions-link'>
                            <span>Actions</span>
                        </Link>
                        {proposalMenu.proposalInfo && this.renderActions()}

                        <Link onClick={() => this.props.actions.showDock('proposal notes', {
                            value: id,
                            label: proposalMenu.proposal_no,
                            type: 'follow ups'
                        }, proposalMenu && proposalMenu.proposalInfo && proposalMenu.proposalInfo.contactEmail)}
                        >Notes</Link>
                        {onShowFilter &&
                            <Button
                                className="pointer"
                                bsStyle="warning"
                                onClick={() => onShowFilter()}>
                                Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                            </Button>
                        }
                    </Col>

                </Row>}
                <Panel
                    collapsible
                    expanded={false}
                    className="bottom0"
                />
                <ReactDock position='right'
                           isVisible={showDock}
                           dimMode="none"
                           defaultSize={window.innerWidth > 990 ? 0.3 : 0.9}
                           dockStyle={{overflow: 'hidden'}}
                           zIndex={1031}
                >
                    <div className="close-dock">
                        <strong className="margin10 pull-left">Proposal Cost Info</strong>
                        <div className="pull-right small-margin">
                            <Glyphicon glyph='remove' className="pointer margin10"
                                       onClick={() => this.setState({showDock: null})}/>
                        </div>
                    </div>
                    <Row>
                        <Col xs={12} className='proposal-cost'>
                            <div>
                                <Row className='bottom10'>
                                    <Col xs={12}>
                                        <h6><b>Proposal Estimate</b></h6>
                                        <div><span className='text-blue'>Labor:</span><span
                                            className='small-margin text-green'>{<Dollars
                                            amount={service_price}/>}</span></div>
                                    </Col>
                                </Row>
                                <Row className='bottom10'>
                                    <Col xs={4}>
                                        <h6><b>Estimated Time</b></h6>
                                        <div>
                                            <span className='text-blue'>Labor:</span>
                                            <span
                                                className='small-margin text-green'>{convertMillisToTime(service_hours * 60 * 60 * 1000)}</span>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <h6><b>Actual Time</b></h6>
                                        <div>
                                            <span className='text-blue'>Labor: </span>
                                            <span
                                                className='small-margin text-green'>{convertMillisToTime(actual_seconds * 1000)}</span>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <h6><b>Totals</b></h6>
                                        <span className='text-blue'>PMH: </span>
                                        <span className='small-margin text-green'><Dollars
                                            amount={actual_seconds > 0 ? parseFloat(service_price / (actual_seconds / 3600)) : 0}/></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <h6><b>Bill Time</b></h6>
                                        {
                                            billTime.map((time) => <div key={time.name}>
                                                <span className='text-blue'>{time.name}: </span>
                                                <span
                                                    className='small-margin text-green'>{convertMillisToTime(time.value * 1000)}</span>
                                            </div>)
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <h6><b>Non Bill Time</b></h6>
                                        <div className="non-billable">
                                            {
                                                nonBillTime.map((time) => <div key={time.name}>
                                                    <span className='text-blue'>{time.name}: </span>
                                                    <span
                                                        className='small-margin text-green'>{convertMillisToTime(time.value * 1000)}</span>
                                                </div>)
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {proposalWorkOrders.map(pwo => (<div>
                                <Row className='bottom10'>
                                    <Col xs={12}>
                                        <h4><b>WO #{pwo.work_order_no}</b></h4>
                                    </Col>
                                </Row>
                                <Row className='bottom10'>
                                    <Col xs={12}>
                                        <h6><b>Work Order Estimate</b></h6>
                                        <div><span className='text-blue'>Labor:</span><span
                                            className='small-margin text-green'>{<Dollars
                                            amount={pwo.service_price}/>}</span></div>
                                    </Col>
                                </Row>
                                <Row className='bottom10'>
                                    <Col xs={4}>
                                        <h6><b>Estimated Time</b></h6>
                                        <div>
                                            <span className='text-blue'>Labor:</span>
                                            <span
                                                className='small-margin text-green'>{convertMillisToTime(pwo.service_hours * 60 * 60 * 1000)}</span>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <h6><b>Actual Time</b></h6>
                                        <div>
                                            <span className='text-blue'>Labor: </span>
                                            <span
                                                className='small-margin text-green'>{convertMillisToTime(pwo.actual_seconds * 1000)}</span>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <h6><b>Totals</b></h6>
                                        <span className='text-blue'>PMH: </span>
                                        <span className='small-margin text-green'><Dollars
                                            amount={pwo.actual_seconds > 0 ? parseFloat(pwo.service_price / (pwo.actual_seconds / 3600)) : 0}/></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <h6><b>Bill Time</b></h6>
                                        {
                                            pwo.billTime.map((time) => <div key={time.name}>
                                                <span className='text-blue'>{time.name}: </span>
                                                <span
                                                    className='small-margin text-green'>{convertMillisToTime(time.value * 1000)}</span>
                                            </div>)
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <h6><b>Non Bill Time</b></h6>
                                        <div className="non-billable">
                                            {
                                                pwo.nonBillTime.map((time) => <div key={time.name}>
                                                    <span className='text-blue'>{time.name}: </span>
                                                    <span
                                                        className='small-margin text-green'>{convertMillisToTime(time.value * 1000)}</span>
                                                </div>)
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>))}
                        </Col>
                    </Row>
                </ReactDock>
                {this.state.isVHModalOpened
                    && <VersionHistoryModal onHide={() => this.setState({isVHModalOpened: false})}
                                            proposalId={Number(this.props.id)}
                                            isVHModalOpened={this.state.isVHModalOpened}/>
                }
                {this.state.showInactiveModal && <InactiveModal
                    inactiveSite={this.props.customer?.inactive_site}
                    inactiveCustomer={this.props.customer?.inactive_customer}
                    onHide={() => this.setState({showInactiveModal: false})}
                    onSave={(e) => this.confirmDuplicate(e, proposalMenu.id)}
                />}
            </div>
        );
    }
}

ProposalMenu.propTypes = {
    id: PropTypes.object,
    proposal: PropTypes.object,
    email: PropTypes.object,
    reloadKey: PropTypes.any,
};

ProposalMenu.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        email: state.auth.email,
        theme: state.theme.theme,
        roles: state.auth.roles
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalMenu);
