import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    Button,
    Col,
    Glyphicon,
    Grid,
    ListGroup,
    ListGroupItem,
    OverlayTrigger,
    Panel,
    Row,
    Tooltip
} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import * as Actions from "./CrewWorkOrdersApi";
import * as EquipmentActions from "../lists/EquipmentChecklistModalApi"
import Dollars from "../../components/Dollars";
import {GoogleMap, Marker} from "react-google-maps";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import {
    defaultDateFormat,
    defaultDateTimeFormat,
    defaultMapParams,
    mapPersonSearch,
    select
} from "../../common/commonHandlers";
import './CrewWorkOrders.scss';
import CrewWorkOrdersDetails from "./CrewWorkOrdersDetails";
import {browserHistory} from "react-router";
import {geolocated} from 'react-geolocated';
import dot from './dot.png'
import Datetime from "react-datetime";
import moment from "moment";
import Select from "react-select";
import Secured from "../../common/Secured";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import MDSpinner from "react-md-spinner";
import UpdateJobStatusModal from "./UpdateJobStatusModal";
import {defineMessages, injectIntl} from "react-intl";
import {buildMessages} from "../../common/CommonMessages";
import {getBasicMarker} from "../../utilities";
import * as _ from "lodash";

const isMobile = window.screen.width <= 1024;
const actions = {...Actions, ...EquipmentActions}


const messages = buildMessages(defineMessages({
    daysRoute: {
        id: 'CrewWorkOrders.DaysRoute.Label',
        defaultMessage: 'Days route'
    },
    downloadRoutePDF: {
        id: 'CrewWorkOrders.DownloadRoutePDF.Label',
        defaultMessage: 'Download route pdf'
    },
    editCrew: {
        id: 'CrewWorkOrders.EditCrew.Header',
        defaultMessage: 'Edit Crew'
    },
    guests: {
        id: 'CrewWorkOrders.Guests.Select.Placeholder',
        defaultMessage: 'Guests'
    },
    confirm: {
        id: 'CrewWorkOrders.Confirm.Button.Label',
        defaultMessage: 'Confirm'
    },
    resources: {
        id: 'CrewWorkOrders.Resources.Button.Label',
        defaultMessage: 'Resources'
    },
    stops: {
        id: 'CrewWorkOrders.Stops.Label',
        defaultMessage: 'Stops'
    },
    route: {
        id: 'CrewWorkOrders.Route.Label',
        defaultMessage: 'Route'
    },
    startJob: {
        id: 'CrewWorkOrders.StartJob.Button.Label',
        defaultMessage: 'Start Job'
    }
}))

class CrewWorkOrders extends ResourceComponent {

    state = {
        ready: false,
        jobs: [],
        employees: [],
        all_employees: [],
        selectedWorkOrderId: null,
        selectedEventId: null,
        equipments: [],
        resource: {scheduler_event_date_from: moment().startOf('day')},
        myLocation: false,
        useCompanyLocation: false,
        from: null,
        to: null,
        messageType: 'unread',
        personId: null,
        reloadDetails: false,
        downloadInProgress: false
    };

    sameCrew = (job_crew) => {
        const {resource} = this.state;
        let selected_crew = resource.scheduler_event_guests.map(guest => guest.id)
        if (!selected_crew.includes(resource.person_id)) {
            selected_crew.push(resource.person_id)
        }
        return JSON.stringify(job_crew.sort()) === JSON.stringify(selected_crew.sort());
    }
    colorFormatter = (status) => {
        switch (status) {
            case 'Completed':
                return 'completed';
            case 'In Progress':
                return 'in progres';
            case 'Pending':
                return 'pending';
            default:
                return 'pending';
        }
    };

    renderWorkPlanHeader = (name, plan, i) => {
        const {formatMessage} = this.props.intl;

        return <div key={i} className={name === 'today' ? 'pointer' : 'panel-heading'}>
            <Row>
                <Col xs={3}>
                    {name === 'today' ? formatMessage(messages.route) : name}
                </Col>
                <Col xs={3}>
                    {`${plan.stops} ${formatMessage(messages.stops)}`}
                </Col>
                <Col xs={3}>
                    {`${parseFloat(plan.hours).toFixed(2)} Hrs`}
                </Col>
                <Col xs={3}>
                    {plan.total && <Dollars amount={parseFloat(plan.total)}/>}
                </Col>
            </Row>
        </div>
    };

    formatDate = (date, allDay) => {
        return allDay ?
            `All Day (${moment(date).utc().format(defaultDateFormat)})`
            :
            moment(date).format(defaultDateTimeFormat)

    };

    handleReoccurringJobs = (job, copyJobs) => {
        const initialDay = moment(job.date_from).utc().dayOfYear();
        const selectedDay = moment(this.state.resource.scheduler_event_date_from).dayOfYear();

        const copiedJob = copyJobs.find(j => j.id === job.id);

        const isInLimit = () => {
            if(job.occurrence === "occurrence_daily") {
                if(job.number_of_events > 0) {
                    return (selectedDay - moment(copiedJob.date_from).utc().dayOfYear() < job.number_of_events) && (selectedDay >= moment(copiedJob.date_from).utc().dayOfYear());
                }
                if(job.reoccurring_end_date) {
                    const firstOccurrenceEvent = moment(copiedJob.date_from).utc().dayOfYear()
                    const dayOfYearFrom = moment(copiedJob.date_from)
                        .add(selectedDay-initialDay, "day")
                        .utc()
                        .dayOfYear()

                    const dayOfYearEnd = moment(job.reoccurring_end_date)
                        .utc()
                        .add(1, "day")
                        .dayOfYear();

                    return dayOfYearFrom <= dayOfYearEnd && selectedDay >= initialDay && dayOfYearFrom >= firstOccurrenceEvent
                }
            }

            if(job.occurrence === "occurrence_weekly") {
                if(job.number_of_events > 0) {
                    const firstOccurrenceEvent = moment(copiedJob.date_from).utc().dayOfYear()
                    const lastPossibleOccurrenceOfEvent = moment(copiedJob.date_from)
                        .add(job.number_of_events, 'weeks')
                        .utc()
                        .dayOfYear();

                    return selectedDay < lastPossibleOccurrenceOfEvent && selectedDay >= firstOccurrenceEvent;
                }

                if(job.reoccurring_end_date) {
                    const firstOccurrenceEvent = moment(copiedJob.date_from).utc().dayOfYear()
                    const dayOfYearFrom = moment(copiedJob.date_from)
                        .add(selectedDay-initialDay, "day")
                        .utc()
                        .dayOfYear()

                    const lastPossibleOccurrenceOfEvent = moment(job.reoccurring_end_date)
                        .utc()
                        .dayOfYear();

                    return dayOfYearFrom < lastPossibleOccurrenceOfEvent + 7 && dayOfYearFrom >= firstOccurrenceEvent
                }
            }

            if(job.occurrence === "occurrence_monthly") {
                if(job.number_of_events > 0) {
                    const firstOccurrenceEvent = moment(copiedJob.date_from).utc().dayOfYear()
                    const lastPossibleOccurrenceOfEvent = moment(copiedJob.date_from)
                        .add(job.number_of_events, 'month')
                        .utc()
                        .dayOfYear();

                    return selectedDay < lastPossibleOccurrenceOfEvent && selectedDay >= firstOccurrenceEvent;
                }

                if(job.reoccurring_end_date) {
                    const firstOccurrenceEvent = moment(copiedJob.date_from).utc().dayOfYear()
                    const dayOfYearFrom = moment(copiedJob.date_from)
                        .add(selectedDay-initialDay, "day")
                        .utc()
                        .dayOfYear()

                    const lastPossibleOccurrenceOfEvent = moment(job.reoccurring_end_date)
                        .add(1, "month")
                        .utc()
                        .dayOfYear();

                    return dayOfYearFrom < lastPossibleOccurrenceOfEvent && dayOfYearFrom >= firstOccurrenceEvent
                }
            }

            if(job.occurrence === "occurrence_custom") {
                if(job.number_of_events > 0) {
                    const firstOccurrenceEvent = moment(copiedJob.date_from).utc().dayOfYear()
                    const lastPossibleOccurrenceOfEvent = moment(copiedJob.date_from)
                        .add(job.number_of_events * job.custom_occurrence, 'day')
                        .utc()
                        .dayOfYear();

                    return selectedDay < lastPossibleOccurrenceOfEvent && selectedDay >= firstOccurrenceEvent;
                }

                if(job.reoccurring_end_date) {
                    const firstOccurrenceEvent = moment(copiedJob.date_from).utc().dayOfYear()
                    const dayOfYearFrom = moment(copiedJob.date_from)
                        .add(selectedDay-initialDay, "day")
                        .utc()
                        .dayOfYear()

                    const lastPossibleOccurrenceOfEvent = moment(job.reoccurring_end_date)
                        .add(job.custom_occurrence, "day")
                        .utc()
                        .dayOfYear();

                    return dayOfYearFrom < lastPossibleOccurrenceOfEvent && dayOfYearFrom >= firstOccurrenceEvent
                }
            }

            return false;
        }

        if(!isInLimit() && (job?.number_of_events > 0 || job?.reoccurring_end_date)) {
            job.hidden = true;
            return;
        }

        if(selectedDay >= initialDay && job.occurrence === "occurrence_daily") {
            job.date_from = moment(job.date_from).add(selectedDay-initialDay, "day").utc().format()
        }

        const selectedDayOfWeek = moment(this.state.resource.scheduler_event_date_from).day();
        const jobDayOfWeek = moment(job.date_from).day();

        if(job.occurrence === "occurrence_weekly") {
            if(selectedDayOfWeek === jobDayOfWeek) {
                job.date_from = moment(job.date_from).set("dayOfYear", selectedDay).utc().format()
            } else {
                job.hidden = true;
            }
        }

        const selectedDayOfMonth = moment(this.state.resource.scheduler_event_date_from).date();
        const jobDayOfMonth = moment(job.date_from).date();

        if(job.occurrence === "occurrence_monthly") {
            if(selectedDayOfMonth === jobDayOfMonth) {
                job.date_from = moment(job.date_from).set("dayOfYear", selectedDay).utc().format();
            } else {
                job.hidden = true;
            }
        }

        if(job.occurrence === "occurrence_custom") {
            const firstOccurrenceEvent = moment(copiedJob.date_from).utc().dayOfYear()
            const daysCountFromStart = selectedDay - firstOccurrenceEvent
            if((daysCountFromStart >= 0) && (daysCountFromStart % job.custom_occurrence === 0)) {
                job.date_from = moment(job.date_from).set("dayOfYear", selectedDay).utc().format()
            } else {
                job.hidden = true;
            }
        }

    }

    renderJobs = () => {
        const {jobs} = this.state;
        const {formatMessage} = this.props.intl;

        return jobs.map((job, i) => {
            return job.work_order_no ? <div key={i}
                                            className={job.selected ? `panel-heading job-line pointer text-white highlighted ${this.colorFormatter(job.status)}` : `panel-heading job-line pointer text-white ${this.colorFormatter(job.status)}`}
                                            onClick={() => {
                                                this.selectMarkerNoScroll('jobs')(job);
                                                this.setState({selectedEventId: null}, () => {
                                                    this.setState({
                                                        selectedWorkOrderId: job.scheduler_event_resource_id,
                                                        selectedEventId: job.id
                                                    }, () => {
                                                        isMobile && browserHistory.push(`/crew_work_order_details/${job.id}`)
                                                    });
                                                })
                                            }}>
                <span><strong>{`#${job.number || i + 1}`}</strong><b
                    className='small-margin'>{this.formatDate(job.date_from, job.all_day)}</b><b
                >{job.full_address}</b><strong className='small-margin'>
                    {job.services_total &&
                        <Dollars
                            amount={parseFloat(job.services_total)}/>
                    }
                    <b className='small-margin'>#{job.work_order_no}</b>
                </strong></span>
                    <OverlayTrigger placement="top"
                                    overlay={!job.time_track_in_progress && !this.sameCrew(job.crew_ids) ? <Tooltip
                                        id="tooltip">
                                        Job crew is different than selected, please confirm crew first
                                    </Tooltip> : <></>}>
                    <span>
                             <Button
                                 className={`btn start-btn ${job.time_track_in_progress ? 'btn-danger' : 'btn-success'} `}
                                 disabled={!job.time_track_in_progress && !this.sameCrew(job.crew_ids)}
                                 style={{pointerEvents: !job.time_track_in_progress && !this.sameCrew(job.crew_ids) ? 'none' : 'auto'}}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     job.time_track_in_progress ? this.props.actions.getJobServices(job.id, res => {
                                         this.setState({showJobStatusModal: true, services: res, job: job})
                                     }) : this.startAllTimeTracks(job.id)
                                 }}>{job.time_track_in_progress ? job.all_services_completed ? formatMessage(messages.jobCompleted) : formatMessage(messages.updateJobStatus) : formatMessage(messages.startJob)}</Button>
                    </span>
                    </OverlayTrigger>
                </div>
                :
                <div key={i} className={`panel-heading job-line pointer text-white ${this.colorFormatter(job.status)}`}>
                    <span>
                        <strong>{`#${job.number || i + 1}`}</strong>
                        {job.date_from && <b className='small-margin'>{this.formatDate(job.date_from, job.all_day)}</b>}
                        {job.full_address && <b className='mr-9'>{job.full_address}</b>}
                        {job.name && <b className='mr-9'>{job.name}</b>}
                    </span>
                </div>
        })
    };
    startAllTimeTracks = (scheduler_event_id) => {
        const {resource, jobs} = this.state
        const person_ids = resource.scheduler_event_guests.map(guest => guest.id)
        const now = moment();

        this.props.actions.startTimeTracks(scheduler_event_id, now, person_ids, (res) => {
            jobs[jobs.findIndex(j => j.id === scheduler_event_id)].time_track_in_progress = true
            this.setState({reloadDetails: !this.state.reloadDetails, jobs: jobs})
        })
    }
    updateJobStatus = (scheduler_event_id) => {
        const {jobs} = this.state
        const now = moment();

        this.props.actions.updateJobStatus(scheduler_event_id, now, (res) => {
            jobs[jobs.findIndex(j => j.id === scheduler_event_id)].time_track_in_progress = false
            this.setState({reloadDetails: !this.state.reloadDetails, jobs: jobs})
        })
    }
    updateJobButton = (scheduler_event_id, value, field) => {
        const {jobs} = this.state
        if (field === 'time_track_in_progress') {
            jobs[jobs.findIndex(j => j.id === scheduler_event_id)].time_track_in_progress = value
        }
        if (field === 'all_services_completed') {
            jobs[jobs.findIndex(j => j.id === scheduler_event_id)].all_services_completed = value
        }
        this.setState({jobs: jobs})
    }
    updateEventsGuests = () => {
        const {resource, jobs, employee} = this.state
        const now = moment();
        this.setState({loading: true, selectedEventId: null})
        let guests_ids = resource.scheduler_event_guests.map(g => g.id)
        //we add selected employee updating events
        if (!guests_ids.includes(resource.person_id)) {
            guests_ids.push(resource.person_id)
        }
        const selectedEmployeePersonId = resource.person_id
        let schedulerEventsResourceIds = jobs.filter(j => j.work_order_no).map(job => job.scheduler_event_resource_id)
        this.props.actions.updateJobsCrew(schedulerEventsResourceIds, guests_ids, selectedEmployeePersonId, now, res => {
            this.reload()
        })
    }

    reload = () => {
        const {resource, selectedEventId} = this.state;
        this.setState(resource, () => this.props.actions.load(this.state.resource, result => {

            let schedulerEventsIds = result.jobs.map(job => job.id)
            this.props.actions.getCrew(schedulerEventsIds, res => {
                const guests = res.crew || [];
                const guestOptions = mapPersonSearch(guests);
                this.guestSearch.initTempOptions(guestOptions);

                const copyJobs = _.cloneDeep(result.jobs);

                const newJobs = result.jobs.map((job) => {
                    if (job.occurrence !== "single_event") {
                        this.handleReoccurringJobs(job, copyJobs);
                    } else {
                        const selectedDay = moment(this.state.resource.scheduler_event_date_from).dayOfYear();
                        const initialDay = moment(job.date_from).utc().dayOfYear();
                        if (selectedDay !== initialDay) {
                            job.hidden = true;
                        }
                    }
                    return job;
                })

                const copyNewJobs = _.cloneDeep(newJobs);

                copyNewJobs.forEach((child) => {
                    if (child.parent_id) {
                        const parentIndex = copyNewJobs.findIndex((parent) => parent.id === child.parent_id && moment(child.date_from).dayOfYear() === moment(parent.date_from).dayOfYear());
                        copyNewJobs[parentIndex] = child;
                    }
                });

                const filteredJobs = copyNewJobs.filter((job) => !job.completed && !job.hidden && !job.deleted)
                const sortedFilteredJobs = _.orderBy(filteredJobs, (j) => {
                    return moment(j.date_from).format('HH:mm');
                }, ['asc']);
                const numberedJobs = sortedFilteredJobs.map((job, i) => ({ ...job, number: i + 1 }));

                this.setState({
                    ...result,
                    jobs: numberedJobs,
                    resource: {...resource, scheduler_event_guests: guests},
                    ready: true,
                    loaded: false,
                    useCompanyLocation: result.use_company_location
                })
            })
            if (result.jobs?.length > 0) {
                const index = result.jobs.findIndex(job => job.id === selectedEventId)

                if (index > -1) {
                    result.jobs[index].selected = true
                }
            }
            this.setState({loading: false})
        }))
    };

    componentDidMount() {
        const {location, isAuthenticated, roles} = this.props;
        let {resource} = this.state;
        const correctUrl = window.location.href.replace('#/', '');
        let url = new URL(correctUrl);
        const workOrderId = url.searchParams.get("work_order_id");
        const showThread = url.searchParams.get("showThread");
        let userRole = null
        const highRoles = ['sales_arborist', 'scheduler', 'admin']

        if (roles?.includes('crew') && !roles?.some(r => highRoles.includes(r))) {
            userRole = 'crew'
        } else if (roles?.includes('technician') && !roles?.some(r => highRoles.includes(r))) {
            userRole = 'technician'
        } else if (roles?.includes('subcontractor') && !roles?.some(r => highRoles.includes(r))) {
            userRole = 'subcontractor'
        }

        this.setState({urlWorkOrderId: workOrderId, showThread: true})

        if (isAuthenticated) {
            this.props.actions.loadContextData(userRole, data => {
                this.setState({
                    messages: data.crewMessages,
                    data,
                    employees: data.employees,
                    all_employees: data.all_employees
                }, () => {
                    const employee = data.employees.find(e => e.employee_id === this.props.state.auth.employee_id);

                    if (workOrderId && showThread && employee && employee.value) {
                        this.showWorkOrderThread(workOrderId, employee.value)
                    }
                    if (!resource.person_id) {
                        resource.person_id = employee?.value;
                    }
                    if (location.query.scheduler_event_date_from) {
                        resource.scheduler_event_date_from = moment(location.query.scheduler_event_date_from.substring(1, location.query.scheduler_event_date_from.length - 1));
                        this.setState(resource, this.reload);
                    } else {
                        this.reload();
                    }
                })
            })
            this.guestSearch = this.buildSearchComponent(
                'scheduler_event_guests',
                this.props.actions.search,
                mapPersonSearch
            );
        } else if (!isAuthenticated) {
            browserHistory.push('/login');
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {person_id, urlWorkOrderId} = this.state;
        const correctUrl = window.location.href.replace('#/', '');
        let url = new URL(correctUrl);
        const workOrderId = url.searchParams.get("work_order_id");
        const showThread = url.searchParams.get("showThread");

        if ((urlWorkOrderId !== workOrderId) && showThread) {
            this.setState({urlWorkOrderId: workOrderId}, () => {
                if (workOrderId && showThread && person_id) {
                    this.showWorkOrderThread(workOrderId, person_id)
                }
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        const {coords} = this.props;
        if (!coords && nextProps.coords) {
            this.setState({loaded: false});
        }
    }

    getZIndex = asset => {
        if (asset.selected === true || asset.__locked === true) {
            //we injected this property to modify state on the next rerender - its slow. maybe we shoudl instead use setState?
            return this.state.jobs.length + 10
        } else {
            return this.state.jobs.findIndex(a => a.id === asset.id) + 1
        }

    }

    prepareDaysRoute = (jobs) => {
        const {client} = this.props
        const {useCompanyLocation} = this.state;

        let latitude;
        let longitude;
        let lat;
        let lng;

        if (useCompanyLocation && client.organization_latitude && client.organization_longitude) {
            latitude = client.organization_latitude
            longitude = client.organization_longitude
        } else {
            latitude = jobs[jobs.length - 1] && jobs[jobs.length - 1].latitude
            longitude = jobs[jobs.length - 1] && jobs[jobs.length - 1].longitude
        }

        lat = jobs[0] && jobs[0].latitude
        lng = jobs[0] && jobs[0].longitude

        let src = `https://www.google.com/maps/dir/${latitude},${longitude}/${lat},${lng}`;
        let wavySrc = '';

        if (jobs.length < 1) {
            return null;
        } else if (jobs.length === 1) {
            return src;
        } else if (jobs.length >= 2) {
            src = `https://www.google.com/maps/dir/${latitude},${longitude}/`
            jobs && jobs.length > 0 && jobs.forEach(s => {
                wavySrc += `${s.latitude},${s.longitude}/`
            });
            let result = src + wavySrc;
            return result.slice(0, result.length - 1);
        }
    };

    showWorkOrderThread = (work_order_id, personId) => {
        const {getSchedulerEvent} = this.props.actions
        const {resource} = this.state

        getSchedulerEvent(work_order_id, personId, ({id, person_id, date_from, date_to}) => {
            resource.scheduler_event_id = id
            resource.person_id = person_id
            resource.scheduler_event_date_from = moment(date_from).startOf('day')


            this.setState({
                resource,
                selectedWorkOrderId: work_order_id,
                from: date_from,
                to: date_to,
                selectedEventId: id
            }, () => {
                this.reload()
                isMobile && browserHistory.push(`/crew_work_order_details/${id}`)
            })
        })
    }

    render() {
        const {
            work_plan,
            ready,
            selectedWorkOrderId,
            equipments,
            selectedEventId,
            resource,
            employees,
            all_employees,
            myLocation,
            user,
            downloadInProgress
        } = this.state;
        const {formatMessage} = this.props.intl;
        let {jobs} = this.state;
        const {isGeolocationAvailable, isGeolocationEnabled, coords, client, roles, state} = this.props;
        const {markAsRead} = this.props.actions
        let guestOptions = this.guestSearch ? this.guestSearch.searchOptions() : [];
        guestOptions = guestOptions.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        )
        if (isGeolocationAvailable && isGeolocationEnabled && coords) {
            let customMarker = {
                latitude: coords.latitude,
                longitude: coords.longitude,
                name: "Here you are!",
                coords: true,

            };
            jobs = jobs.filter(job => !job.coords);
            myLocation && jobs.push(customMarker);
        }

        jobs.forEach((job, i) => {
            const arborist = all_employees.find(e => e.label === job.arborist);
            const arboristColor = (arborist ? arborist.color : '#3a87ad');
            const jobNumber = job.number || i + 1
            if (job.selected) {
                job.icon = getBasicMarker({
                    selected: true,
                    color: '#f8e500',
                    label: `#${jobNumber}`
                })
            } else if (job.coords) {
                job.icon = dot;
            } else {
                job.icon = getBasicMarker({
                    selected: false,
                    color: arboristColor,
                    label: `#${jobNumber}`
                })
            }

        });
        const allowPrintPDF = (isGeolocationAvailable && isGeolocationEnabled && myLocation ? jobs.filter(j => j.work_order_no || j.coords).length >= 2 : jobs.filter(j => j.work_order_no || j.coords).length >= 1)
        const employee = employees.find(e => e.value === resource.person_id);
        const employeeOptions = employees.filter(x => x.label !== 'Not assigned');
        const seenMarkers = {};

        return (ready ?
                <Grid fluid id='crew-work-orders'>
                    {
                        !isGeolocationAvailable
                            ? <Row>
                                <Col xs={12}>
                                    Your browser does not support Geolocation
                                </Col>
                            </Row>
                            : !isGeolocationEnabled
                            && <Row>
                                <Col xs={12}>
                                    Geolocation is not enabled
                                </Col>
                            </Row>
                    }

                    <Row className="vcenter">
                        <Col md={3} xs={state?.userStatus?.userStatus?.has_equipment_questions ? 5 : 6}
                             className="vcenter">
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={false}
                                value={resource.scheduler_event_date_from}
                                inputProps={{placeholder: 'Date'}}
                                onChange={this.dateResourceAttr('scheduler_event_date_from', () => {
                                    this.setState({selectedEventId: null})
                                    this.reload()
                                })}/>
                        </Col>
                        <Col md={3} xs={state?.userStatus?.userStatus?.has_equipment_questions ? 5 : 6}
                             className="space-between">
                            <a href={this.prepareDaysRoute(jobs)} target="_blank"
                               className={allowPrintPDF ? '' : 'disabled'}
                               onClick={e => {
                                   !allowPrintPDF && e.preventDefault();
                               }}>
                                {formatMessage(messages.daysRoute)}
                            </a>
                            {((!client?.hidden_prices && roles?.every(r => r === 'crew' || r === 'technician')) ||
                                    (client?.display_subcontractor_price && roles?.every(r => r === 'subcontractor')) ||
                                    roles?.some(r => r === 'admin' || r === 'sales_arborist' || r === 'scheduler')) &&
                                <a className={`${allowPrintPDF ? '' : 'disabled'} pointer`}
                                   onClick={() => allowPrintPDF && !downloadInProgress ? this.setState({downloadInProgress: true}, () => this.props.actions.downloadRoutePdf({
                                           ...resource,
                                           locale: this.props.locale
                                       }, jobs,
                                       () => this.setState({downloadInProgress: false}),
                                       () => this.setState({downloadInProgress: false})
                                   )) : ""}>
                                    {formatMessage(messages.downloadRoutePDF)}
                                </a>}
                        </Col>
                        {state?.userStatus?.userStatus?.has_equipment_questions &&
                            <Col md={6} xs={2} className="text-right">
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id={`tooltip-refresh`}>
                                            Your equipment checklist is not completed. Click to show it.
                                        </Tooltip>
                                    }
                                >
                                    <Glyphicon
                                        glyph='exclamation-sign'
                                        className="pointer margin10 font24"
                                        onClick={() => {
                                            this.props.actions.checkEquipmentsChecklist(false, res => {
                                                if (res) {
                                                    this.props.actions.fetchChecklistModal(true)
                                                }
                                            })
                                        }}/>
                                </OverlayTrigger>
                            </Col>}
                    </Row>
                    <Row className={!isMobile ? 'selects-container' : ''}>
                        <Col sm={12} md={3} style={{zIndex: 4}}>
                            <div className='employee-header'>{formatMessage(messages.employee)}</div>
                            <Secured roles='admin, scheduler, crew, subcontractor, technician, sales_arborist'>
                                <Select className="Select full-width mt10" classNamePrefix="select"
                                        options={employeeOptions}
                                        value={select(employeeOptions, employee && employee.value)}
                                        onChange={e => {
                                            resource.person_id = e.value;
                                            this.setState({selectedEventId: null, resource}, this.reload);
                                        }}
                                />
                            </Secured>
                        </Col>
                        <Col sm={12} md={3} style={{fontSize: 12}}>
                            <div className='crew-edit-header'>
                                <div>{formatMessage(messages.editCrew)}</div>
                                <Button onClick={() => this.updateEventsGuests()}
                                        disabled={this.state.loading || !roles?.some(role => ["sales_arborist", "scheduler", "it_engineering", "admin"].includes(role))}
                                        className='mt10 btn btn-success'> {this.state.loading ?
                                    <MDSpinner size={12}/> : formatMessage(messages.confirm)}</Button>
                            </div>
                            <Select className="Select full-width mt10"
                                    classNamePrefix="select"
                                    value={select(guestOptions.filter(o => o.value !== employee.value), resource.scheduler_event_guests.filter(seg => seg.value !== employee.value))}
                                    options={guestOptions.filter(o => o.value !== employee.value)}
                                    placeholder={formatMessage(messages.guests)}
                                    onInputChange={this.guestSearch.searchGuess}
                                    isMulti
                                    onChange={e => {
                                        this.setState({
                                            resource: {
                                                ...resource,
                                                scheduler_event_guests: guestOptions.filter(o => o.value === employee.value).concat(e)
                                            }
                                        })
                                    }}
                                    isClearable
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Panel
                            expanded={false}
                        />
                    </Row>
                    <Row>
                        <Col xs={isMobile ? 12 : 6}>
                            <Row>
                                <Col xs={12} className='bottom10'>
                                    <Panel
                                        className="wo-panel resource-panel"
                                        collapsible
                                        header={<div className='text-center text-white pointer'>
                                            <strong>{`${formatMessage(messages.resources)}: (${equipments.length})`}</strong>
                                        </div>}
                                    > <ListGroup>
                                        {
                                            equipments && equipments.map((equipment, i) =>
                                                <ListGroupItem key={i}
                                                               header={equipment}/>
                                            )}</ListGroup>

                                    </Panel>
                                    <Panel
                                        className="wo-panel"
                                        collapsible
                                        header={this.renderWorkPlanHeader('today', work_plan['today'])}
                                    >
                                        {Object.keys(work_plan).map((keyName, i) => {
                                            if (keyName !== 'today')
                                                return this.renderWorkPlanHeader(keyName, work_plan[keyName], i)
                                        })}
                                    </Panel>

                                </Col>
                                <Col xs={12}>
                                    <ColorCheckbox value={this.state.myLocation}
                                                   label={formatMessage(messages.myLocation)}
                                                   onChange={() => this.setState({myLocation: !this.state.myLocation})}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <GoogleMapLoader
                                        containerElement={
                                            <div
                                                id="woMap"
                                                className="remaining vertical-responsive-height"
                                            />
                                        }
                                        googleMapElement={
                                            <GoogleMap
                                                ref={it => this.refGoogleMap(it, jobs.filter(job => job.latitude))}
                                                {...defaultMapParams(jobs, false, null, client)}
                                            >
                                                {jobs.map(job => {
                                                    let duplicates = []
                                                    if (seenMarkers.hasOwnProperty(job.longitude)) {
                                                        job.longitude += 0.0004
                                                    } else {
                                                        seenMarkers[job.longitude] = false
                                                    }
                                                    return job.latitude && (
                                                        <Marker
                                                            key={job.id}
                                                            position={{lat: job.latitude, lng: job.longitude}}
                                                            icon={job.icon}
                                                            title={job.name}
                                                            onClick={() => {
                                                                this.selectMarkerNoScroll('jobs')(job);
                                                            }}
                                                            zIndex={this.getZIndex(job)}
                                                        />
                                                    )
                                                })}
                                            </GoogleMap>
                                        }
                                    />
                                </Col>
                                <Col xs={12} className='bottom10'>
                                    {this.renderJobs()}
                                </Col>
                            </Row>
                        </Col>
                        {
                            !isMobile &&
                            <Col xs={6}>
                                {
                                    selectedWorkOrderId && selectedEventId &&
                                    <CrewWorkOrdersDetails reloadData={this.state.reloadDetails}
                                                           updateJobButton={this.updateJobButton}
                                                           workOrderId={selectedWorkOrderId}
                                                           workOrdersPrintAll={allowPrintPDF}
                                                           filters={{
                                                               scheduler_event_date_from: resource.scheduler_event_date_from,
                                                               person_id: resource.person_id
                                                           }}
                                                           schedulerEventId={selectedEventId}
                                                           handleChangeStatus={this.reload}/>
                                }
                            </Col>
                        }
                    </Row>
                    {this.state.showJobStatusModal && <UpdateJobStatusModal
                        show={this.state.showJobStatusModal}
                        onHide={() => this.setState({showJobStatusModal: false})}
                        stopAllTimeTracks={() => this.updateJobStatus(this.state.job.id)}
                        services={this.state.services.work_order_proposal_services.sort((a, b) => parseFloat(a.service_number) - parseFloat(b.service_number))}
                    />}
                </Grid> : <div className="center-absolute-h-w"><MDSpinner size={80}/></div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        client: state.client.customerInfo,
        locale: state.userStatus.userStatus.locale,
    };
}


export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CrewWorkOrders)));
