import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import '@fullcalendar/bootstrap/main.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import {Button, Col, FormGroup, Glyphicon, Grid, Panel, Row} from "react-bootstrap";
import * as actions from "./SchedulerApi"
import createApiService from "../../common/clientActionsBuilder";
import Filter from "./Filter";
import moment from "moment"
import SchedulerEventDialog from "./SchedulerEventDialog";
import {
    colors,
    convertFloatToHour,
    mapEvents,
    positionCalendarEvents,
    reverseColor,
    schedulerEventStatuses
} from "../../common/commonHandlers";
import WorkOrders from "./WorkOrders";
import {debounce} from "throttle-debounce";
import {Link} from "react-router";
import Datetime from "react-datetime";
import FilterModal from "./FilterModal";
import WorkOrdersFilterModal from "./WorkOrdersFilterModal";
import EmailDialog from "../../components/email/EmailDialog";
import "./Scheduler.scss";
import DayHeader from "./Components/DayHeader";
import EventContent from "./Components/EventContent";
import ResourceComponent from "../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import NewFilterModal from './SchedulerNewFilterModal'
import PresetSearch from './PresetSearch';
import Dollars from "../../components/Dollars";
import {removePsFromWo} from "../../actions/workOrders";
import ReoccurringEventModal from "./ReoccurringEventModal";
import _ from "lodash";

let schedulerEventsActions = createApiService('scheduler_events', 'scheduler_event', 'Scheduler events');
let workOrderActions = createApiService('work_orders', 'work_order', 'Work order');
const api = {
    ...schedulerEventsActions,
    loadWorkOrders: workOrderActions.load,
    ...actions,
    removePsFromWo
};
const screen32 = window.innerWidth >= 1024 && window.innerHeight <= 600;
const heightProportion = ((window.innerWidth > 767) && (window.innerWidth < 1169)) ? 0.75 : 0.78
const width1300 = window.screen.width <= 1300
const width1400 = window.screen.width <= 1400
const width1175 = window.screen.width <= 1175
const smMobile = window.screen.width <= 476
const UTCFormat = 'YYYY-MM-DD[T]HH:mm:ss[Z]'; //ignore timezone
const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'

const isMobile = window.innerWidth < 992;
class Calendar extends ResourceComponent {
    calendarComponentRef = React.createRef();
    state = {
        events: [], holidays: [], workOrders: [], loaded: false, birthdays: [], startDates: [], truckServices: [],
        showModal: false, event: null, midDate: moment(),
        workOrdersFilter: {
            eq: ">",
            status: ['IN_WORK_ORDER']
        },
        filters: null,
        employees: [],
        filterVisible: false,
        date: moment(),
        workOrdersFilterModal: false,
        calendarVisible: false,
        newSchedulerView: null,
        emailDialogShow: false,
        serviceTypes: [],
        services: [],
        priorities: [],
        equipment: [],
        customerSearchResults: [],
        workOrderSearchResults: [],
        citySearchResults: [],
        zipSearchResults: [],
        proposalServiceStatuses: [],
        ready: false,
        workOrdersLoaded: false,
        proposalInfo: {},
        fullSizeFilterPanel: true,
        fullSizeWOPanel: true,
        resourceSearch: "",
        resource: {},
        searchValue: "",
        isNewFilterModalOpened: false,
        isEmployeeFilterOpened: true,
        isHolidaySettingsOpened: true,
        isTypeFilterOpened: true,
        isColorFilterOpened: true,
        isStatusesFilterOpened: true,
        isDeletedFilter: false,
        selectedFilter: null,
        selectedFilterToDelete: null,
        userSearchFilters: [],
        isProcessing: false,
        userBaseSettings: null,
        periodType: null,
        isLoading: false,
        selectedWorkOrder: null,
        showDisabled: false,
        totalApproved: null,
        editedReoccurringEvent: null,
    };

    period = {dayGridMonth: 'months', timeGridWeek: 'weeks', timeGridDay: 'days'};

    constructor(props) {
        super(props);
        this.delayedViewRender = debounce(500, this.viewRender);
        this.delayedSearch = debounce(500, this.loadEvents);
        this.delayedFilterName = debounce(1000, this.setFilterName);
        this.delayedEventSearch = debounce(1000, this.setPresetSearch);
        this.delayedFilterSaving = debounce(1000, this.saveFilter);
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        const {clientId} = this.props;
        this.loadWorkOrders();
        this.props.actions.updateUserPreset({id: 0});
        document.addEventListener('mousedown', this.handleClickDatepicker);

        this.props.actions.getFilters(user_filters => {
            this.setState({userBaseSettings: user_filters})
        })

        this.props.actions.loadFilterData(data => {
            this.setState({...data});
        });

        this.props.actions.getUserCalendarState(calendar => {
            const {
                type,
                fullSizeFilterPanel,
                fullSizeWOPanel,
                isEmployeeFilterOpened,
                isTypeFilterOpened,
                isColorFilterOpened,
                isStatusesFilterOpened,
                selectedFilter,
                periodType,
                isHolidaySettingsOpened
            } = calendar;
            this.props.actions.getUserPresets(presets => this.setState({userSearchFilters: presets}, () => {
                const hasUserFilters = this.state.userSearchFilters && this.state.userSearchFilters.length > 0
                const existingFilter = this.state.userSearchFilters.find(f => f.id === selectedFilter)
                const activePreset = hasUserFilters && existingFilter ? selectedFilter : null

                if (type === 'modern') {
                    this.setState({
                        newSchedulerView: true,
                        fullSizeFilterPanel,
                        fullSizeWOPanel,
                        isEmployeeFilterOpened,
                        isTypeFilterOpened,
                        isColorFilterOpened,
                        isStatusesFilterOpened,
                        isHolidaySettingsOpened,
                        selectedFilter: activePreset,
                        periodType
                    }, () => {
                        this.loadCalendarContext(clientId, callback => {
                            this.updateFilterPreset(callback, activePreset)
                        })
                    })
                } else if (type === 'classic') {
                    this.setState({
                        newSchedulerView: false,
                        fullSizeFilterPanel,
                        fullSizeWOPanel,
                        isEmployeeFilterOpened,
                        isTypeFilterOpened,
                        isColorFilterOpened,
                        isStatusesFilterOpened,
                        isHolidaySettingsOpened,
                        selectedFilter: activePreset,
                        periodType
                    }, () => {
                        this.loadCalendarContext(clientId, callback => {
                            this.updateFilterPreset(callback, activePreset)
                        })
                    })
                }
            }))
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {newSchedulerView, view, userSearchFilters, selectedFilter, client, userBaseSettings, events, start, end, filters} = this.state;
        const {showTaskDialog} = this.props
        let activeFilter;
        let loadingEvents = false;

        if (prevState.newSchedulerView !== newSchedulerView) {
            this.renderCalendarSettings()
            loadingEvents = true;
        }
        if (prevState.events !== events) {
            let calendar = this.calendarComponentRef.current.getApi()
            this.loadTotalApproved()

            setTimeout(()=>{
                calendar && calendar.refetchEvents()
            }, 1000)

        }

        if ((prevState.selectedFilter !== selectedFilter || (prevState.newSchedulerView !== newSchedulerView) && userSearchFilters?.length > 0)) {
            if (!client?.sync_calendar_settings) {
                if (!loadingEvents) {
                    this.loadEvents()
                }
                loadingEvents = true;
            } else {
                if (selectedFilter) {
                    activeFilter = userSearchFilters.find(f => f.id === selectedFilter)
                    this.setState({employees: activeFilter.settings.users}, () => {
                        if (!loadingEvents) {
                            this.loadEvents()
                        }
                    })
                    loadingEvents = true;
                }
            }
        }

        if (prevProps.showTaskDialog !== showTaskDialog) {
            this.setState({scroll: false})
        }

        const dateFormat = 'YYYY-MM-DD';
        if ((!prevState.start && !prevState.end) || (prevState.start?.format(dateFormat) !== start?.format(dateFormat)) || (prevState.end?.format(dateFormat) !== end?.format(dateFormat))) {
            !loadingEvents && this.loadEvents()
        }
        if (prevState.filters !== this.state.filters || prevState.start !== this.state.start || prevState.end !== this.state.end ) {
            this.loadTotalApproved()
        }
    }

    loadCalendarContext = (clientId, callback) => {
        this.props.actions.loadContextData(clientId, data => {
            data.employees.forEach(e => {
                if (!data.filters.users.map(e => e.label).includes(e.label || `${e.first_name} ${e.last_name}`)) {
                    let user = e;
                    e.selected = true;
                    user.disabled = e.disabled
                    data.filters.users.push(user);
                } else {
                    let index = data.filters.users.findIndex(u => u.label === e.label)
                    data.filters.users[index].disabled = e.disabled;
                }
            });

            this.props.actions.saveFilter(data.filters, () => {
                this.setState({
                    ...data,
                    ready: true,
                    userSearchFilters: data.searchFilters
                }, () => {
                    callback(this.state.userSearchFilters)
                })
            })
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickDatepicker);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.newSchedulerView && nextProps.newSchedulerView === false) {
            this.componentDidMount();
        }
    }

    handleClickDatepicker = (e) => {
        const datePicker = document.getElementById('scheduler-date-picker');
        const calendarButton = document.getElementsByClassName('fc-calendar-button');
        if (datePicker && calendarButton && !datePicker.contains(e.target) && !calendarButton[0].contains(e.target)) {
            this.setState({calendarVisible: false})
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.showTaskDialog === false && this.props.showTaskDialog === true) {
            this.reload(false);
        }
        return true;
    }

    reload = (shouldScroll) => {
        this.loadEvents(shouldScroll);
        this.loadWorkOrders();
    };

    loadWorkOrders = () => {
        const {workOrdersFilter} = this.state;
        let filter = {};

        if (workOrdersFilter.from) {
            filter.from = workOrdersFilter.from.format(dateFormat);
        }
        if (workOrdersFilter.to) {
            filter.to = workOrdersFilter.to.format(dateFormat);
        }
        if (workOrdersFilter.status && workOrdersFilter.status.length > 0) {
            filter.status = workOrdersFilter.status;
        }
        if (workOrdersFilter.serviceType && workOrdersFilter.serviceType.length > 0) {
            filter.serviceType = workOrdersFilter.serviceType;
        }
        if (workOrdersFilter.service && workOrdersFilter.service.length > 0) {
            filter.service = workOrdersFilter.service;
        }
        if (workOrdersFilter.priority && workOrdersFilter.priority.length > 0) {
            filter.priority = workOrdersFilter.priority;
        }
        if (workOrdersFilter.equipment && workOrdersFilter.equipment.length > 0) {
            filter.equipment = workOrdersFilter.equipment;
        }
        if (workOrdersFilter.customer && workOrdersFilter.customer.length > 0) {
            filter.customer = workOrdersFilter.customer;
        }
        if (workOrdersFilter.reason && workOrdersFilter.reason.length > 0) {
            filter.reason = workOrdersFilter.reason;
        }
        if (workOrdersFilter.city && workOrdersFilter.city.length > 0) {
            filter.city = workOrdersFilter.city;
        }
        if (workOrdersFilter.zip && workOrdersFilter.zip.length > 0) {
            filter.zip = workOrdersFilter.zip;
        }
        if (workOrdersFilter.workOrder && workOrdersFilter.workOrder.length > 0) {
            filter.workOrder = workOrdersFilter.workOrder;
        }
        const compare = (limit, eq, value) => {
            if (limit) {
                limit = parseFloat(limit);
                if (eq === '=') {
                    return value === limit;
                } else if (eq === ">") {
                    return value > limit;
                } else if (eq === "<") {
                    return value < limit;
                }
            }
            return true;
        };

        this.props.actions.loadWorkOrders(filter, workOrders => {
            workOrders = workOrders
                .filter(x => compare(workOrdersFilter.price, workOrdersFilter.eq, x.service_price))
                .filter(x => compare(workOrdersFilter.hours, workOrdersFilter.eq, x.service_hours));
            this.setState({workOrders, loaded: true, workOrdersLoaded: true, isLoading: false}, () => {
            });
        });
    };

    toggleFilter = (key, calendarState) => {
        this.setState({[key]: !this.state[key]}, () => {
            const {setUserCalendarState} = this.props.actions

            calendarState[key] = this.state[key]
            setUserCalendarState(calendarState)
        })
    }

    syncCalendars = () => {
        const {client, newSchedulerView} = this.state;

        if (this.state && (client && !client.sync_calendar_settings) && newSchedulerView) {
            return false
        } else {
            return true
        }
    }

    setActiveFilter = (selected) => {
        let {userSearchFilters} = this.state;
        let userFilters = []

        userSearchFilters.map(f => {
            if (f.id === selected) {
                f.active = true
            }

            userFilters.push(f)
        })

        this.setState({userSearchFilters: userFilters})
    }

    removeDisabledClass = () => {
        let buttons = document.getElementsByClassName('fc-button-group')
        let todayButton = document.getElementsByClassName('fc-today-button')

        if (buttons && buttons.length > 0 && todayButton && todayButton.length > 0) {
            [...buttons].map(button => button.classList.remove('fc-state-disabled'));
            [...todayButton].map(button => button.classList.remove('fc-today-state-disabled'));
        }
    }

    loadTotalApproved = () => {
        let {start, end, filters} = this.state;

        if (start && end && filters) {
            let users = []
            if (filters.showDisabled) {
                users = filters.users.filter(u => u.value && u.selected)
            } else {
                users = filters.users?.filter(u => !u.disabled && u.selected && u.value)
            }

            this.props.actions.loadTotalApproved({
                from: start.format(),
                to: end.format(),
                person_ids: users.map(u => u.value)
            }, (response) => {
                this.setState({totalApproved: response.total_approved});
                this.removeDisabledClass()
            })
        }
    }

    loadEvents = (shouldScroll = true) => {
        const {employee_id, roles} = this.props;
        let {resource, start, end, newSchedulerView, filters} = this.state;


        let employeeStartDates = [];
        let crewRoleEvents = []

        if (start && end && filters) {
            let users = []
            if (filters.showDisabled) {
                users = filters.users.filter(u => u.value && u.selected)
            } else {
                users = filters.users?.filter(u => !u.disabled && u.selected && u.value)
            }

            this.props.actions.loadEventsData({
                from: start.format(),
                to: end.format(),
                q: resource.q,
                sync_calendar: this.syncCalendars(),
                person_ids: users.filter(u => u.employee_id === employee_id).map(u => u.value)
            }, response => {
                let events = response.events.map(mapEvents)
                if (response && response.startDates && response.startDates.length > 0) {
                    if (roles.includes("admin") || roles.includes("scheduler") || roles.includes("sales_arborist")) {
                        employeeStartDates = response.startDates.map(e => ({
                            id: e.id,
                            person_id: e.person_id,
                            name: e.name,
                            date: e.date
                        }))
                    } else {
                        const employeeCrewEvent = response.startDates.find(e => e.id === employee_id)

                        if (employeeCrewEvent) {
                            crewRoleEvents.push(employeeCrewEvent)

                            employeeStartDates = crewRoleEvents.map(e => ({
                                id: e.id,
                                person_id: e.person_id,
                                name: e.name,
                                date: e.date
                            }))
                        }
                    }
                }

                response && response.holidays && response.holidays.length > 0 && response.holidays.map(r => {
                    r.date = moment(r.date).format(dateFormat)
                })

                const reoccurringEvents = this.getReoccurringEvents(events, start);
                events = this.markCopiedParentEvents(events);

                if (newSchedulerView) {
                    this.setState({
                        employees: response.employees
                    })
                }

                this.setState({
                    events: [...events, ...reoccurringEvents],
                    holidays: response.holidays,
                    birthdays: response.birthdays,
                    truckServices: response.truckServices,
                    startDates: employeeStartDates,
                    totalApproved: response.totalApproved,
                    scroll: isMobile ? false : (events.length > 0 && shouldScroll)
                });
                this.removeDisabledClass()
            });
        }
    };

    markCopiedParentEvents = (events) => {
        const childEvents = events.filter(e => !!e.parent_id);
        return events.map(e => {
            const hasCopy = childEvents.find(ce =>
                +ce.parent_id === +e.id && moment(ce.start).isSame(moment(e.start), 'minute')
            );
            return hasCopy ? {...e, hasCopy} : e;
        });
    }

    getReoccurringEvents = (events, start) => {
        const reoccurringEvents = [];
        reoccurringEvents.push(...this.getEveryDayEvents(events, start))
        reoccurringEvents.push(...this.getWeeklyEvents(events, start))
        reoccurringEvents.push(...this.getMonthlyEvents(events, start))
        reoccurringEvents.push(...this.getCustomOccurrenceEvents(events, start))
        return reoccurringEvents;
    }

    emailDialogShow = () => {
        this.setState({emailDialogShow: !this.state.emailDialogShow})
    }

    isDateBeforeEnd = (date, endDate) => {
        return endDate ? moment(date).endOf('day') <= moment(endDate).endOf('day') : true;
    }

    getEveryDayEvents = (events, start) => {
        const reoccurringEvents = [];
        const copiedEvents = events.filter(e => e.parent_id !== null);

        events.filter(e => e.occurrence === "occurrence_daily").forEach(e => {
            const originalDateFrom = moment(e.start);
            const originalDateTo = moment(e.end);
            const timeFrom = originalDateFrom.format(timeFormat);
            const timeTo = originalDateTo.format(timeFormat);
            const newDate = moment(e?.numberOfEvents ? e.start : start);
            const parentId = e.id;
            const daysToLoop = e?.numberOfEvents > 0 ? e.numberOfEvents : this.state.periodType === 'timeGridWeek' ? 7 : 42;
            const daysDiff = originalDateTo.startOf("day").diff(originalDateFrom.startOf("day"), "d")

            for (let i = 0; i < daysToLoop; i++) {
                const newDateFrom = newDate.clone().add(i, 'days').format(dateFormat);
                const newDateTo = newDate.clone().add(i + daysDiff, 'days').format(dateFormat);
                if (!this.isDateBeforeEnd(newDateFrom, e.reoccurringEndDate)) {
                    continue;
                }
                const newDateFromISO = `${newDateFrom}T${timeFrom}.000`;
                const newDateToISO = `${newDateTo}T${timeTo}.000`;

                const newEvent = {
                    ...e,
                    parentId,
                    id: Math.random() * 100000000000000000,
                    start: newDateFromISO,
                    end: newDateToISO,
                };
                const hasCopy = copiedEvents.find(copiedEvent =>
                    moment(copiedEvent.start).isSame(moment(newDateFromISO), 'minute') &&
                    copiedEvent.parent_id === parentId
                );

                if (moment(e.start).dayOfYear() !== moment(newDateFromISO).dayOfYear() &&
                    moment(e.start) < moment(newDateToISO) && !hasCopy) {
                    reoccurringEvents.push(newEvent);
                }
            }
        });
        return reoccurringEvents;
    };

    takeFirstNItems = (arr, n) => {
        if (n <= 0) {
            return [];
        }

        if (n >= arr.length) {
            return arr;
        } else {
            return arr.slice(0, n);
        }
    }

    getWeeklyEventsInMonth = (sourceEvent, allEvents) => {
        const {end} = this.state;
        const eventStartTime = moment(sourceEvent.start).format(timeFormat);
        const eventEndTime = moment(sourceEvent.end).format(timeFormat);
        const copiedEvents = allEvents.filter(e => e.parent_id === sourceEvent.id);

        let weeklyEventsDates = []
        let lastEventDate = moment(sourceEvent.start).toISOString();
        weeklyEventsDates.push(lastEventDate)
        while (moment(lastEventDate).add(7, 'days').endOf('day') < end.endOf('day')) {
            lastEventDate = moment(lastEventDate).add(7, 'days').toISOString()
            weeklyEventsDates.push(lastEventDate)
        }

        const res = weeklyEventsDates.filter(dateString => moment(dateString) > moment(sourceEvent.start)).map((dateString) => {
            const date = moment(dateString).format(dateFormat);
            const dateFrom = `${date}T${eventStartTime}.000`;
            const dateTo = `${date}T${eventEndTime}.000`;
            return {
                ...sourceEvent,
                parentId: sourceEvent.id,
                id: Math.random() * 100000000000000000,
                start: dateFrom,
                end: dateTo
            }
        }).filter(event => {
            const hasCopy = copiedEvents.find(e => moment(e.start).dayOfYear() === moment(event.start).dayOfYear())
            return !hasCopy
        }).filter(event => this.isDateBeforeEnd(event.start, sourceEvent.reoccurringEndDate));

        if (sourceEvent.numberOfEvents && sourceEvent.numberOfEvents > 0) {
            return this.takeFirstNItems(res, sourceEvent.numberOfEvents - 1)
        } else {
            return res;
        }

    }


    getWeeklyEvents = (events, start) => {
        let reoccurringEvents = [];
        const copiedEvents = events.filter(e => e.parent_id !== null);

        events.filter(e => e.occurrence === "occurrence_weekly").forEach((e) => {
            const parentId = e.id;
            if (['timeGridDay', 'timeGridWeek'].includes(this.state.periodType)) {
                const selectedWeekEnd = moment(start).endOf('week')
                const sourceEventStart = e.start;
                const weeksPassed = selectedWeekEnd.diff(moment(sourceEventStart).startOf('day'), 'weeks');
                if (weeksPassed !== 0) {
                    const newStart = moment(sourceEventStart).add(weeksPassed, 'weeks').utc().toISOString();
                    const newEnd = moment(e.end).add(weeksPassed, 'weeks').utc().toISOString();
                    const hasCopy = copiedEvents?.find(e => e?.parent_id === parentId && moment(e?.start).dayOfYear() === moment(newStart).dayOfYear());

                    const isInLimit = e.numberOfEvents && e.numberOfEvents > 0 ? this.isDateBeforeEnd(
                        newStart,
                        moment(e.end).add(e.numberOfEvents - 1, 'weeks')
                    ) : true;

                    if (!hasCopy && this.isDateBeforeEnd(newStart, e.reoccurringEndDate) && isInLimit) {
                        const reoccurringEvent = {
                            ...e, parentId: e.id, id: Math.random() * 100000000000000000, start: newStart, end: newEnd,
                        }
                        reoccurringEvents.push(reoccurringEvent);
                    }
                }
            } else if (this.state.periodType === 'dayGridMonth') {
                reoccurringEvents.push(...this.getWeeklyEventsInMonth(e, events));
            }
        });
        return reoccurringEvents;
    };

    getMonthlyEvents = (events, start) => {
        const {midDate} = this.state;
        const reoccurringEvents = [];
        const copiedEvents = events.filter(e => e.parent_id !== null);

        events.filter(e => e.occurrence === "occurrence_monthly").forEach((e) => {
            if (this.state.periodType === 'dayGridMonth' && moment(e.start).endOf('month') <= moment(midDate).endOf('month')) {
                const sourceEventStart = moment(e.start).startOf('day');
                const monthsPassed = Math.round((midDate).endOf('month').diff(sourceEventStart, 'months', true));
                const newStart = moment(e.start).add(monthsPassed, 'months').toISOString();
                const newEnd = moment(e.end).add(monthsPassed, 'months').toISOString();
                const parentId = e.id;

                const hasCopy = copiedEvents?.find(e => e.parent_id === parentId && moment(e.start).dayOfYear() === moment(newStart).dayOfYear());

                const isInLimit = e.numberOfEvents && e.numberOfEvents > 0 ? this.isDateBeforeEnd(
                    newStart,
                    moment(e.end).add(e.numberOfEvents - 1, 'month')
                ) : true;

                if (!hasCopy && this.isDateBeforeEnd(newStart, e.reoccurringEndDate) && isInLimit && monthsPassed > 0) {
                    reoccurringEvents.push({
                        ...e, parentId: e.id, id: Math.random() * 100000000000000000,
                        start: newStart,
                        end: newEnd,
                    })
                }
            }
            if (moment(e.start).endOf('month') <= moment(start).endOf('month')) {
                const sourceEventStart = moment(e.start).startOf('day');
                const monthsPassed = Math.ceil((start).startOf('day').diff(sourceEventStart, 'months', true));
                const newStart = moment(e.start).add(monthsPassed, 'months').toISOString();
                const newEnd = moment(e.end).add(monthsPassed, 'months').toISOString();
                const parentId = e.id;

                const hasCopy = copiedEvents?.find(e => (e.parent_id === parentId || e.parent_id === e.id) && moment(e.start).dayOfYear() === moment(newStart).dayOfYear());

                const isInLimit = e.numberOfEvents && e.numberOfEvents > 0 ? this.isDateBeforeEnd(
                    newStart,
                    moment(e.end).add(e.numberOfEvents - 1, 'month')
                ) : true;

                if (!hasCopy && this.isDateBeforeEnd(newStart, e.reoccurringEndDate) && isInLimit && monthsPassed > 0) {
                    reoccurringEvents.push({
                        ...e, parentId: e.id, id: Math.random() * 100000000000000000,
                        start: newStart,
                        end: newEnd,
                    })
                }
            }
        });
        return _.uniqWith(reoccurringEvents, (a, b) => a.start === b.start && a.end === b.end && a.parent_id === b.parent_id);
    };

    getCustomOccurrenceEvents = (events, start) => {
        const reoccurringEvents = [];
        const copiedEvents = events.filter(e => e.parent_id !== null);

        events.filter(e => e.occurrence === "occurrence_custom").forEach(e => {
            const originalDateFrom = moment(e.start);
            const originalDateTo = moment(e.end);
            const timeFrom = originalDateFrom.format(timeFormat);
            const timeTo = originalDateTo.format(timeFormat);
            const newDate = moment(e?.numberOfEvents || e?.reoccurringEndDate ? e.start : start);
            const parentId = e.id;
            const daysToLoop = e?.numberOfEvents > 0 ? e.numberOfEvents : this.state.periodType === 'timeGridWeek' ? 7 : 42;
            const customOccurrence = e.custom_occurrence;

            if (this.state.periodType === "timeGridDay") {
                const dayDifference = moment(start).startOf("day").utc().diff(originalDateFrom.startOf("day").utc(), 'days');
                const isNDaysApart = dayDifference % customOccurrence === 0;
                const newDateFrom = originalDateFrom.clone().set('dayOfYear', moment(start).dayOfYear()).format(dateFormat);
                const newDateTo = originalDateTo.clone().set('dayOfYear', moment(start).dayOfYear()).format(dateFormat);

                const totalOccurrenceCount = dayDifference / customOccurrence;

                if (isNDaysApart &&
                    ((e.reoccurringEndDate && this.isDateBeforeEnd(newDateFrom, e.reoccurringEndDate)) ||
                        (e?.numberOfEvents > 0 && totalOccurrenceCount <= e.numberOfEvents))
                ) {
                    const newDateFromISO = `${newDateFrom}T${timeFrom}.000`;
                    const newDateToISO = `${newDateTo}T${timeTo}.000`;

                    const newEvent = {
                        ...e,
                        parentId,
                        id: Math.random() * 100000000000000000,
                        start: newDateFromISO,
                        end: newDateToISO,
                    };

                    const hasCopy = copiedEvents.find(copiedEvent =>
                        moment(copiedEvent.start).dayOfYear() === moment(newDateFromISO).dayOfYear() &&
                        copiedEvent.parent_id === parentId
                    );

                    if (moment(e.start).dayOfYear() !== moment(newDateFromISO).dayOfYear() &&
                        moment(e.start) < moment(newDateToISO) && !hasCopy) {
                        reoccurringEvents.push(newEvent);
                    }
                }

            } else {
                for (let i = 0; i < daysToLoop; i++) {
                    const newDateFrom = newDate.clone().add(i * customOccurrence, 'days').format(dateFormat);
                    const newDateTo = newDate.clone().add(i * customOccurrence, 'days').format(dateFormat);

                    if (this.isDateBeforeEnd(newDateFrom, e.reoccurringEndDate)) {
                        const newDateFromISO = `${newDateFrom}T${timeFrom}.000`;
                        const newDateToISO = `${newDateTo}T${timeTo}.000`;

                        const newEvent = {
                            ...e,
                            parentId,
                            id: Math.random() * 100000000000000000,
                            start: newDateFromISO,
                            end: newDateToISO,
                        };

                        const hasCopy = copiedEvents.find(copiedEvent =>
                            moment(copiedEvent.start).dayOfYear() === moment(newDateFromISO).dayOfYear() &&
                            copiedEvent.parent_id === parentId
                        );

                        if (moment(e.start).dayOfYear() !== moment(newDateFromISO).dayOfYear() &&
                            moment(e.start) < moment(newDateToISO) && !hasCopy) {
                            reoccurringEvents.push(newEvent);
                        }
                    }
                }
            }
        });
        return reoccurringEvents;
    }

    handleEvents = (events) => {
        const {filters, periodType} = this.state;
        const duplicatedEvents = events.filter((v, i, a) =>
            a.findIndex(t => (t.id === v.id && t.extendedProps.person_id === v.extendedProps.person_id)) !== i
        )
        if (duplicatedEvents.length) {
            duplicatedEvents.map(evt => evt.remove())
        }
        filters.defaultView = periodType;
        if (filters.defaultView !== 'dayGridMonth') {
            this.setEventsOrder(events.filter(e => !e.allDay))
        }
    }

    handleChange = e => {
        if (e.event?.extendedProps?.parentId) {
            this.setState({editedReoccurringEvent: e});
        } else {
            this.handleEventChange(e);
        }
    }

    handleCopyEvent = (e) => {
        this.handleEventChange(e, true);
    }

    handleRescheduleAll = (e) => {
        this.setState({isReoccurringChildChanged: true}, () => this.handleEventChange(e, false))
    }

    handleRescheduleAllFuture = (e) => {
        this.setState({isReoccurringChildChanged: true}, () => this.handleEventChange(e, false, true))
    }

    handleEventChange = (e, createCopy = false, createFutureCopy = false) => {
        this.setState({isLoading: true})
        const {events} = this.state;
        let event = e.event?.extendedProps
        let date_from = e.event.start
        let date_to = e.event.end ? e.event.end : moment(e.event.start).add(1, 'hour')
        if (e.event.allDay) {
            date_from = moment(e.event.start).format(UTCFormat);
            date_to = e.event.end ? moment(e.event.end).format(UTCFormat) : moment(e.event.start).add(1, 'day').format(UTCFormat);
        }
        let schedulerEvent = {
            id: event.parentId ? event.parentId : e.event.id,
            date_from,
            all_day: e.event.allDay,
            date_to,
            date_from_before: e.oldEvent.start,
            date_to_before: e.oldEvent.end,
            scheduler_event_resource_id: event.scheduler_event_resource_id,
            create_copy: event.parentId && createCopy,
            create_future_copy: event.parentId && createFutureCopy,
            is_parent: event.occurrence !== 'single_event' && !event.parentId,
            number_of_events: event.number_of_events,
            scheduler_event_guests: _.uniq(events.filter(ev => +ev.id === (event.parentId ? +event.parentId : +e.event.id)).map(ev => ev.person_id))
        };

        const existing = events.find(el => +el.id === +e.event.id);
        if(existing){
            existing.start = date_from;
            existing.end = date_to
            existing.all_day = e.event.allDay;
            existing.allDay = e.event.allDay;
        }
        this.setState({events, isReoccurringChildChanged: false}, () =>
            this.props.actions.save(schedulerEvent, () => {
                this.reload(false)
            }));
    };

    handleClick = ev => {
        let event
        if (ev?.event?.id) {
            let tempEvt = this.state.events.filter(e => (e.id === parseInt(ev.event.id)))
            event = tempEvt.find(e => e.person_id === ev.event.extendedProps.person_id) || ev
            event = {...event, id: event.parentId || event.id, date_from: ev.event.start, date_to: ev.event.end}
        } else {
            event = ev
        }
        this.setState({showModal: true, scroll: false, event})
    };

    viewRender = () => {
        const {view, filters, client, periodType} = this.state;
        const differentStart = !moment(view.activeStart).isSame(this.state.start);
        const differentEnd = !moment(view.activeEnd).isSame(this.state.end);
        if ((differentStart || differentEnd)) {
            this.setState({events: []});
            filters.defaultView = periodType;
            this.props.actions.saveFilter(filters, () => {
                this.setState({
                    start: moment(view.activeStart),
                    end: moment(view.activeEnd),
                    filters: filters
                })
            });
        }
    };

    updateDate(date) {
        const {filters} = this.state;
        filters.date = date;
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.gotoDate(date.format());
        this.setState({
            scroll: !isMobile,
            filters,
            midDate: date,
        }, this.delayedViewRender);
    }

    toggleCalendar = () => {
        const {calendarVisible} = this.state;
        this.setState({calendarVisible: !calendarVisible})
    }

    resize = () => {
        if (window.innerWidth !== this.state.oldInnerWidth) {
            if (window.innerHeight < window.innerWidth) {
                this.setState({
                    oldInnerWidth: window.innerWidth,
                    calendarWidth: 8,
                    WOWidth: 4
                });
            } else {
                this.setState({
                    oldInnerWidth: window.innerWidth,
                    calendarWidth: 12,
                    WOWidth: 12
                });
            }
        }
    };

    calculateRowHeight = (event_id = null) => {
        let ready = false
        const {client, scroll} = this.state;
        const rows = document.querySelectorAll(".fc-timegrid-slots  tbody > tr")
        const calendar = document.getElementsByClassName('fc-scroller fc-scroller-liquid-absolute')[0]
        if (calendar && rows.length > 0) {
            const rowHeight = 14.5
            for (let i = 0; i < rows.length; i++) {
                rows[i].style.height = `${rowHeight}px`;
                ready = i === rows.length - 1
            }
        }

        if (ready && scroll) {
            const startRow = (client && client.start_date / 3600) * 2
            const events = this.calendarComponentRef.current.getApi().getEvents()
            const currentIndex = events.findIndex(evt => evt.extendedProps.event_id === event_id)
            const index = startRow ? startRow : 16
            if (index) {
                rows[index] && rows[index].scrollIntoView({
                    behavior: 'smooth'
                });
            }
            if (currentIndex === events.length - 1 || this.state.view.type === 'timeGridDay') {
                this.setState({scroll: false})
            }
        }
    }
    setEventsOrder = (apiEvents) => {
        let {events, filters, view, start} = this.state

        if (filters.users) {
            events = events.filter(e => filters.users.some(u => u.selected && u.value === e.person_id));
        }
        if (filters.statuses) {
            events = events.filter(e => filters.statuses.some(s => s.selected && s.label === e.status));
        }
        if (filters.schedulerEventTypes) {
            events = events.filter(e => filters.schedulerEventTypes.some(s => s.selected && s.label === e.scheduler_event_type_name));
        }
        positionCalendarEvents(events.filter(e => !e.hasCopy), apiEvents.filter(e => !e.hasCopy), view?.type === 'timeGridDay' && start ? new Date(start).getDate() : undefined, view?.type);
    }

    renderSchedulerGrid = () => {
        const {newSchedulerView, fullSizeFilterPanel, fullSizeWOPanel} = this.state;
        if (newSchedulerView) {
            return 9;
        } else if (!newSchedulerView) {
            if (fullSizeFilterPanel && fullSizeWOPanel) {
                return 8;
            } else if (fullSizeFilterPanel && !fullSizeWOPanel) {
                return 10;
            } else if (fullSizeWOPanel && !fullSizeFilterPanel) {
                return 10;
            } else {
                return 12;
            }
        }
    }

    setResourceSearch = e => {
        this.setState({resourceSearch: e.target.value})
    }

    setFilterName = (value, callback) => {
        this.setState({searchValue: value}, callback)
    }

    setPresetSearch = (value) => {
        this.setState(() => ({resource: {...this.state.resource, q: value}}), this.delayedSearch)
    }

    updateFilterPreset = (userSearchFilters, selectedFilter) => {
        let filter;
        const {resource, filters, client, employees} = this.state;
        if (this.state.newSchedulerView && !client.sync_calendar_settings) {
            selectedFilter = null
        }
        if (userSearchFilters && userSearchFilters.length > 0 && selectedFilter) {
            filter = userSearchFilters.find(x => x.id === selectedFilter)
            const {users, schedulerEventTypes, color, statuses, showDisabled} = filter.settings;
            this.props.actions.updateUserPreset({id: filter.id}, () => this.props.actions.getUserPresets(presets => {
                this.setState({
                    from: filter.from,
                    to: filter.to,
                    selectedFilter: filter.id,
                    resource: {...resource, q: filter.settings.q},
                    userSearchFilters: presets,
                    employees: employees,
                    filters: {
                        ...filters,
                        users: users,
                        schedulerEventTypes: schedulerEventTypes,
                        color: color,
                        statuses: statuses,
                        showDisabled: showDisabled
                    }
                }, () => {
                    this.loadEvents()
                })
            }))
        }
    }

    setDisabledState = (value) => this.setState({showDisabled: value})

    saveFilter = () => {
        const {
            selectedFilter,
            isDeletedFilter,
            isNewFilterModalOpened,
            searchValue,
            selectedFilterToDelete,
            filters,
            resource,
            showDisabled,
            calendarState,
            employees
        } = this.state;
        this.setState({isProcessing: true})
        if (selectedFilter && isNewFilterModalOpened && !isDeletedFilter) {
            this.props.actions.updateUserPreset({
                id: selectedFilter,
                activeModal: true,
                name: searchValue,
                settings: {
                    users: filters.users,
                    schedulerEventTypes: filters.schedulerEventTypes,
                    color: filters.color,
                    statuses: filters.statuses,
                    q: resource.q,
                    showDisabled: showDisabled
                }
            }, () => this.props.actions.getUserPresets(presets => {
                this.setState({
                    isNewFilterModalOpened: false,
                    isDeletedFilter: false,
                    userSearchFilters: presets,
                    searchValue: "",
                    isProcessing: false,
                })
            }))
        } else if (isDeletedFilter) {
            this.props.actions.deleteUserPreset({
                id: selectedFilterToDelete,
                active_id: selectedFilter
            }, () => this.props.actions.getUserPresets(filters => {
                this.setState({
                    isNewFilterModalOpened: false,
                    isDeletedFilter: false,
                    userSearchFilters: filters,
                    searchValue: "",
                    selectedFilter: filters.some(x => x.active) ? selectedFilter : null,
                    isProcessing: false
                })
            }))
        } else if (isNewFilterModalOpened) {
            this.props.actions.saveUserPreset({
                id: selectedFilter,
                name: searchValue,
                settings: {
                    users: filters.users,
                    schedulerEventTypes: filters.schedulerEventTypes,
                    color: filters.color,
                    statuses: filters.statuses,
                    q: resource.q,
                    showDisabled: showDisabled,
                }
            }, () => this.props.actions.getUserPresets(filters => {
                const activeFilter = filters.find(x => x.active === true)
                this.setState({
                    isNewFilterModalOpened: false,
                    isDeletedFilter: false,
                    userSearchFilters: filters,
                    searchValue: "",
                    selectedFilter: activeFilter && activeFilter.id ? activeFilter.id : null,
                    isProcessing: false,
                    from: activeFilter.from,
                    to: activeFilter.to,
                    resource: {...resource, q: activeFilter.settings.q},
                    employees: employees,
                    filters: {
                        ...this.state.filters,
                        users: activeFilter.settings?.users,
                        schedulerEventTypes: activeFilter.settings?.schedulerEventTypes,
                        statuses: activeFilter.settings?.statuses,
                        showDisabled: !!showDisabled
                    }
                }, () => {
                    const {setUserCalendarState} = this.props.actions
                    calendarState.selectedFilter = activeFilter.id
                    setUserCalendarState(calendarState)
                    this.loadEvents()
                })
            }))
        }
    }

    setCalendarMargin = () => {
        const {newSchedulerView, fullSizeWOPanel, fullSizeFilterPanel} = this.state;

        if (!newSchedulerView) {
            if (!fullSizeWOPanel && !fullSizeFilterPanel) {
                return 0;
            } else if (!newSchedulerView && !fullSizeFilterPanel) {
                return 0
            }
        }
    }

    renderPresetInput = () => {
        const {newSchedulerView, resource} = this.state;
        return (
            <PresetSearch newSchedulerView={newSchedulerView}
                          q={resource.q}
                          setPresetSearch={this.delayedEventSearch}/>
        )
    }

    calculateBalanceValues = (events, employees, balance) => {
        const {start, end} = this.state;
        let workOrderEvents = []
        let notWorkOrderEvents = []

        let eventsInRange = events.filter(e => moment(e.start).isBetween(start, end) && !e.deleted && !e.hasCopy);

        balance.hours = 0
        balance.woHours = 0
        balance.disabledEmployeesPrice = 0
        balance.disabledEmployees = 0

        eventsInRange = eventsInRange.filter((v, i, arr) => arr.findIndex(t => (t.id === v.id)) === i)
        workOrderEvents = eventsInRange.filter(e => e.scheduler_event_type_name === "Work Order")
        notWorkOrderEvents = eventsInRange.filter(e => e.scheduler_event_type_name !== "Work Order")

        if (workOrderEvents?.length > 0) {
            balance.woHours = this.calculateEventDuration(workOrderEvents)
            let eventPrices = []

            workOrderEvents.map(e => {
                if (e.divided_event_price) {
                    eventPrices.push((parseFloat(e.divided_event_price)))
                } else {
                    eventPrices.push(parseFloat(e.price))
                }
            })
            balance.price = eventPrices.reduce((a, b) => a + b)
        }

        if (notWorkOrderEvents?.length > 0) {
            balance.hours = this.calculateEventDuration(notWorkOrderEvents)
        }

        balance.hours = balance.woHours ? (balance.hours + balance.woHours) : balance.hours

        if (employees?.length > 0) {
            const disabledEmployeesPersonIds = employees.filter(e => e.disabled).map(x => x.value)
            disabledEmployeesPersonIds.map(x => {
                eventsInRange.map(y => {
                    if (x === y.person_id) {
                        const diff = moment.duration(moment(y.end).diff(moment(y.start))).asHours()
                        balance.disabledEmployees = balance.disabledEmployees + diff
                        if (y.scheduler_event_type_name === 'Work Order') {
                            balance.disabledEmployeesPrice = balance.disabledEmployeesPrice + (parseFloat(y.price) / y.hours) * diff
                        }
                    }
                })
            })
        }
    }

    renderBalanceContainer = (balance) => {
        const {isLoading} = this.state

        const disabledEventsPrice = isLoading ? '-' : balance?.disabledEmployeesPrice ? <><Dollars
            amount={balance.disabledEmployeesPrice}/> / </> : null
        const disabledEventsHours = isLoading ? '-' : balance?.disabledEmployees ? <>{balance.disabledEmployees} hrs</> : '-'

        return (
            <div className='classic-balance-container'>
                <span>Total Scheduled hours: {isLoading ? '-' : balance?.hours ? <>{balance?.hours.toFixed(2) / 1} hrs</> : '-'}</span>
                <span
                    className='ml-20'>Scheduled WO hours: {isLoading ? '-' : balance?.woHours ? <>{balance?.woHours.toFixed(2) / 1} hrs</> : '-'}</span>
                <span className='ml-20'>Scheduled WO price: {isLoading ? '-' : balance?.price ?
                    <Dollars amount={balance.price}/> : '-'}</span>
                <span className='ml-20'>Approved: {isLoading ? '-' : this.state.totalApproved ?
                    <Dollars amount={this.state.totalApproved}/> : '-'}</span>
                {balance?.disabledEmployees > 0 && <span
                    className='ml-20'>Disabled employees events: {disabledEventsPrice} {disabledEventsHours}</span>}

            </div>
        )
    }

    calculateEventDuration = (events) => {
        const hours = events.map(se => ({start: se.start, end: se.end}))
        const diff = hours?.length > 0 && hours.map(h => moment.duration(moment(h.end).diff(moment(h.start))).asHours())

        return diff?.length > 0 && diff.reduce((a, b) => a + b)
    }

    renderCalendarSettings = () => {
        let {userSearchFilters, userBaseSettings, newSchedulerView, filters, selectedFilter, client} = this.state;
        let userBaseCalendarSettings = {};

        if (userBaseSettings) {
            userBaseCalendarSettings = userBaseSettings
        }

        if (userSearchFilters && userSearchFilters.length > 0) {
            const activeFilter = userSearchFilters.find(f => f.id === selectedFilter)

            if (activeFilter && newSchedulerView && filters && (client && !client.sync_calendar_settings)) {
                filters.users = userBaseCalendarSettings.users
                filters.schedulerEventTypes = userBaseCalendarSettings.schedulerEventTypes
                filters.color = userBaseCalendarSettings.color
                filters.statuses = userBaseCalendarSettings.statuses
                filters.showDisabled = userBaseSettings.showDisabled
            }
            if (activeFilter && !newSchedulerView && filters && (client && !client.sync_calendar_settings)) {
                filters.users = activeFilter.settings.users
                filters.schedulerEventTypes = activeFilter.settings.schedulerEventTypes
                filters.color = activeFilter.settings.color
                filters.statuses = activeFilter.settings.statuses
                filters.showDisabled = activeFilter.settings.showDisabled
            }
        }
        this.setState({filters}, this.loadEvents)
    }

    setWorkOrder = (workOrder) => {
        this.setState({selectedWorkOrder: workOrder})
    }

    render() {
        let {
            workOrders, events, loaded, workOrdersLoaded, showModal, event, client, fullSizeFilterPanel, fullSizeWOPanel,
            filters, employees, schedulerEventTypes, filterVisible, midDate, calendarVisible, resource, searchValue, isNewFilterModalOpened,
            workOrdersFilterModal, newSchedulerView, ready, holidays, birthdays,truckServices, proposalInfo, startDates, scroll, isEmployeeFilterOpened,
            isTypeFilterOpened, isColorFilterOpened, isStatusesFilterOpened, isDeletedFilter, userSearchFilters, selectedFilter, showFilterSaveBtn,
            selectedFilterToDelete, userBaseSettings, periodType, type, selectedWorkOrder, showDisabled, isLoading, isHolidaySettingsOpened,   editedReoccurringEvent
        } = this.state;
        const {emails} = this.props;
        const sortArray = []

        if (!newSchedulerView) {

            const schedulerHeader = document.querySelector(`.fc-toolbar-title`)

            if (schedulerHeader) {
                if (width1175) {
                    schedulerHeader.style.marginLeft = "100px";
                    schedulerHeader.style.fontSize = "18px";
                } else if (width1300) {
                    schedulerHeader.style.marginLeft = "100px";
                    schedulerHeader.style.fontSize = "20px";
                }
            }
        }

        let filteredHolidays = holidays

        if (filters) {
            if (!filters.showAllHolidays) {
                filteredHolidays = filteredHolidays.filter(h => h.isCustom === filters.showAllHolidays)
            }
            events.forEach(event => {
                if (filters.color === 'employee') {
                    const user = employees.find(e => e.value === event.person_id);
                    const color = user ? (user.color === "default" ? '#3a87ad' : user.color) : '#3a87ad'
                    event.backgroundColor = color
                    event.borderColor = color
                    event.textColor = reverseColor(color)
                    if (sortArray.indexOf(event.person_id) === -1) {
                        sortArray.push(event.person_id)
                    }
                } else if (filters.color === 'type') {
                    event.backgroundColor = colors[parseInt(event.scheduler_event_type_color)];
                    event.borderColor = event.backgroundColor;
                    if (sortArray.indexOf(event.scheduler_event_type_color) === -1) {
                        sortArray.push(event.scheduler_event_type_color)
                    }
                } else {
                    event.backgroundColor = schedulerEventStatuses().find(x => x.value === event.status).color;
                    event.borderColor = event.backgroundColor;
                    if (sortArray.indexOf(event.status) === -1) {
                        sortArray.push(event.status)
                    }
                }
                event.event_id = `${event.id}_${event.backgroundColor}`

            });
            if (filters.users) {
                events = events.filter(e => filters.users.some(u => u.selected && u.value === e.person_id));
            }
            if (filters.statuses) {
                events = events.filter(e => filters.statuses.some(s => s.selected && s.label === e.status));
            }
            if (filters.schedulerEventTypes) {
                events = events.filter(e => filters.schedulerEventTypes.some(s => s.selected && s.label === e.scheduler_event_type_name));
            }
        }
        // full calendar work around
        const syncCalendar = this.props.actions.syncCalendar;
        const toggleCalendar = this.toggleCalendar;
        const reload = this.reload;

        const filterStatuses = {
            employee: isEmployeeFilterOpened,
            type: isTypeFilterOpened,
            color: isColorFilterOpened,
            statuses: isStatusesFilterOpened,
            holidaySettings: isHolidaySettingsOpened
        }

        let filterToDeleteName = "";

        const selectedPreset = userSearchFilters.find(x => x.id === selectedFilterToDelete)

        if (selectedPreset) {
            filterToDeleteName = selectedPreset.name
        }

        const clientStartTime = this.state && client && client.start_date;

        let start_time
        if (clientStartTime) {
            start_time = moment.utc((clientStartTime) * 1000).format(timeFormat)
        }

        let calendarState = {}

        if (userSearchFilters && userSearchFilters.length > 0 && !selectedFilter) {
            userSearchFilters.forEach(f => f.active = false)
        }

        let eventTime;

        if (selectedWorkOrder?.service_hours && !client?.hour_event_duration) {
            const decimalTimeString = selectedWorkOrder?.service_hours;
            const n = new Date(0, 0);
            n.setMinutes(+decimalTimeString * 60);
            eventTime = n.toTimeString().slice(0, 5);
        }

        let balance = {}
        if (events?.length > 0) {
            this.calculateBalanceValues(events, employees, balance)
        }

        const addDisabledClass = () => {
            let buttons = document.getElementsByClassName('fc-button-group')
            let todayButton = document.getElementsByClassName('fc-today-button')

            if (buttons && buttons.length > 0 && todayButton && todayButton.length > 0) {
                [...buttons].map(button => button.classList.add('fc-state-disabled'));
                [...todayButton].map(button => button.classList.add('fc-today-state-disabled'));
            }
        }

        return (
            ready && client ?
                <Grid fluid>
                    <Row className="vcenter">
                        <Col xs={!newSchedulerView ? 2 : 6} className="vcenter">
                            <h2 className={screen32 ? 'mt0 bottom5' : "header-margin"}
                                style={{fontSize: screen32 && 20}}>Calendar</h2>
                        </Col>
                        {isNewFilterModalOpened &&
                            <NewFilterModal
                                title={isDeletedFilter ? "Delete filter" : selectedFilter ? "Edit filter" : "New filter modal"}
                                searchValue={searchValue}
                                filterToDeleteName={filterToDeleteName}
                                userSearchFilters={userSearchFilters}
                                isProcessing={this.state.isProcessing}
                                selectedFilter={selectedFilter}
                                isDeletedFilter={isDeletedFilter}
                                setFilterName={this.delayedFilterName}
                                disabled={((userSearchFilters.some(x => x.name === searchValue) && !selectedFilter) || searchValue.length === 0) && !isDeletedFilter}
                                show={isNewFilterModalOpened}
                                closeButton={() => this.setState({
                                    isNewFilterModalOpened: false,
                                    isDeletedFilter: false,
                                    searchValue: ""
                                })}
                                onHide={() => this.setState({
                                    isNewFilterModalOpened: false,
                                    isDeletedFilter: false,
                                    searchValue: ""
                                })}
                                onSave={this.delayedFilterSaving}
                            >
                            </NewFilterModal>
                        }
                        {!newSchedulerView &&
                            <Col xs={8} className="vertical-align pr-5">
                                <Col md={1}>
                                    <Row className="bottom10">
                                        <Button
                                            onClick={() => this.setState({fullSizeFilterPanel: !fullSizeFilterPanel}, () => {
                                                const {setUserCalendarState} = this.props.actions
                                                const {fullSizeFilterPanel} = this.state

                                                calendarState.fullSizeFilterPanel = fullSizeFilterPanel
                                                setUserCalendarState(calendarState)
                                            })}
                                            className="classic-filter-btn pointer">
                                            <FontAwesomeIcon icon={fullSizeFilterPanel ? faAngleLeft : faAngleRight}
                                                             className="color-white bigger"/>
                                        </Button>
                                    </Row>
                                </Col>
                                <Col md={10} className="bottom10 no-left-padding vcenter">
                                    <Col md={2} className="no-left-padding text-blue pointer"
                                         onClick={() => this.setState({isNewFilterModalOpened: true}, () => {
                                             if (selectedFilter) {
                                                 this.props.actions.getUserPreset(selectedFilter, filter => {
                                                     this.setState({searchValue: filter.name})
                                                 })
                                             }
                                         })}>Save Filter</Col>
                                    <Col md={10} className="flex-wrap no-padding">
                                        {userSearchFilters && userSearchFilters.length > 0 && userSearchFilters.sort((a, b) => a.id - b.id).map((filter, index) => {
                                            const {
                                                users,
                                                schedulerEventTypes,
                                                color,
                                                statuses,
                                                showDisabled
                                            } = filter.settings;
                                            const className = filter.id === selectedFilter ? "classic-filter-element-name bottom0 pointer active-preset" : "classic-filter-element-name bottom0 pointer";
                                            return (
                                                <div className="textCenter vcenter padding-lr-4" key={filter.id}>
                                                    <p className={className} ref={this.myRef}
                                                       onClick={() => {
                                                           if (filter.id === selectedFilter) {
                                                               addDisabledClass()
                                                               this.props.actions.updateUserPreset({id: filter.id}, () => {
                                                                   const {client} = this.state;
                                                                   this.props.actions.loadContextData(client.id, data => {
                                                                       data.filters.users = data.filters.users.map(user => {
                                                                           delete user.color
                                                                           return user
                                                                       })
                                                                       this.setState({
                                                                           selectedFilter: null,
                                                                           userSearchFilters: data.searchFilters,
                                                                           filters: {
                                                                               ...data.filters,
                                                                               date: filters.date
                                                                           },
                                                                           employees: data.employees,
                                                                           resource: {...resource, q: ""},
                                                                       }, () => {
                                                                           const {setUserCalendarState} = this.props.actions
                                                                           calendarState.selectedFilter = null
                                                                           setUserCalendarState(calendarState)
                                                                           this.loadEvents()
                                                                       })
                                                                   })
                                                               })
                                                           } else {
                                                               addDisabledClass()
                                                               this.props.actions.updateUserPreset({id: filter.id}, () => this.props.actions.getUserPresets(presets => {
                                                                   const newFilter = presets.filter(p => p.id === filter.id)[0]

                                                                   this.setState({
                                                                       from: filter.from,
                                                                       to: filter.to,
                                                                       selectedFilter: filter.id,
                                                                       resource: {...resource, q: filter.settings.q},
                                                                       userSearchFilters: presets,
                                                                       employees: employees,
                                                                       filters: {
                                                                           ...filters,
                                                                           users: newFilter.settings?.users,
                                                                           schedulerEventTypes: schedulerEventTypes,
                                                                           statuses: statuses,
                                                                           showDisabled: !!showDisabled
                                                                       }
                                                                   }, () => {
                                                                       const {setUserCalendarState} = this.props.actions
                                                                       calendarState.selectedFilter = filter.id
                                                                       setUserCalendarState(calendarState)
                                                                       this.loadEvents()
                                                                   })
                                                               }))
                                                           }
                                                       }}>
                                                        {filter.name}
                                                    </p>
                                                    {(filter.id !== selectedFilter) &&
                                                        <span className={className} onClick={() => this.setState({
                                                            isDeletedFilter: true,
                                                            isNewFilterModalOpened: true,
                                                            selectedFilterToDelete: filter.id
                                                        })}>
                                <FontAwesomeIcon icon={faTimes} style={{color: filter.active && '#ffffff'}}/>
                                </span>}
                                                </div>
                                            )
                                        })
                                        }
                                    </Col>
                                </Col>
                                <Col md={1}>
                                    <Row className="bottom10 justify-flex-end">
                                        <Button
                                            onClick={() => this.setState({fullSizeWOPanel: !fullSizeWOPanel}, () => {
                                                const {setUserCalendarState} = this.props.actions
                                                const {fullSizeWOPanel} = this.state

                                                calendarState.fullSizeWOPanel = fullSizeWOPanel
                                                setUserCalendarState(calendarState)
                                            })}
                                            className="classic-filter-btn pointer">
                                            <FontAwesomeIcon icon={fullSizeWOPanel ? faAngleRight : faAngleLeft}
                                                             className="color-white bigger"/>
                                        </Button>
                                    </Row>
                                </Col>
                            </Col>}
                        <Col xs={!newSchedulerView ? 2 : 6} className="vcenter hright">
                            <Link className='pointer'
                                  onClick={e => this.setState({newSchedulerView: !newSchedulerView}, () => {
                                      e.preventDefault()
                                      const {setUserCalendarState} = this.props.actions
                                      calendarState.type = newSchedulerView ? 'classic' : 'modern'
                                      calendarState.selectedFilter = selectedFilter
                                      setUserCalendarState(calendarState)
                                  })}
                            >
                                <b>{newSchedulerView ? 'CLASSIC VIEW' : 'MODERN VIEW'}</b>
                            </Link>
                        </Col>
                    </Row>
                    {!newSchedulerView && <hr className={'mt0 bottom0'}/>}
                    <Row>
                        {(filters && newSchedulerView) && <FilterModal
                            value={filters}
                            users={filters?.users}
                            showAllHolidays={filters?.showAllHolidays}
                            employees={employees}
                            show={filterVisible}
                            loadEvents={this.loadEvents}
                            onHide={() => {
                                this.setState({filterVisible: !filterVisible})
                            }}
                            newSchedulerView={newSchedulerView}
                            schedulerEventTypes={schedulerEventTypes}
                            setDisabledState={this.setDisabledState}
                            updateEmployeeColor={(employee, errorCallback) => this.props.actions.updateEmployeeColor(employee, () => {
                                this.loadCalendarContext(this.props.clientId, callback => {
                                    const hasUserFilters = this.state.userSearchFilters && this.state.userSearchFilters.length > 0
                                    const existingFilter = this.state.userSearchFilters.find(f => f.id === selectedFilter)
                                    const activePreset = hasUserFilters && existingFilter ? selectedFilter : null
                                    this.updateFilterPreset(callback, activePreset)
                                });
                            }, () => errorCallback && errorCallback())}
                            onChange={(filters) => {
                                const updateState = () => {
                                    this.setState({filters}, this.delayedViewRender);
                                };
                                if (Object.keys(this.state.filters).length === 0) {
                                    updateState();
                                } else {
                                    if (selectedFilter && !newSchedulerView) {
                                        updateState()
                                    } else {
                                        filters.users = filters.users.map(user => {
                                            delete user.color
                                            return user
                                        })
                                        this.props.actions.saveFilter(filters, updateState);
                                    }
                                }
                            }}
                        />}
                        {!newSchedulerView && fullSizeFilterPanel === true &&
                        <Col className={fullSizeFilterPanel && 'col-md-2 classic-panel mt10'}>
                            <Panel
                                collapsible
                                bsClass="ps-default-panel-wrap"
                                defaultExpanded
                            >
                                <Col className={isMobile ? 'margin-left15' : "mr-9 ml-9"}>
                                    {employees?.length > 0 &&  <Filter
                                        value={filters}
                                        isModalOpened={isNewFilterModalOpened}
                                        activeFilter={Boolean(selectedFilter)}
                                        resource={this.state.resourceSearch}
                                        showFilterSaveBtn={showFilterSaveBtn && !selectedFilter}
                                        setResource={this.setResourceSearch}
                                        toggleFilter={this.toggleFilter}
                                        loadEvents={this.loadEvents}
                                        filterStatuses={filterStatuses}
                                        users={filters?.users}
                                        employees={employees}
                                        showAllHolidays={filters?.showAllHolidays}
                                        setDisabledState={this.setDisabledState}
                                        syncCalendars={client.sync_calendar_settings}
                                        newSchedulerView={newSchedulerView}
                                        schedulerEventTypes={schedulerEventTypes}
                                        userSearchFilters={userSearchFilters}
                                        selectedFilter={selectedFilter}
                                        setActiveFilter={this.setActiveFilter}
                                        onDateChange={(date) => this.updateDate(date)}
                                        updateEmployeeColor={(employee, errorCallback) => this.props.actions.updateEmployeeColor(employee, () => {
                                            this.loadCalendarContext(this.props.clientId, callback => {
                                                const hasUserFilters = this.state.userSearchFilters && this.state.userSearchFilters.length > 0
                                                const existingFilter = this.state.userSearchFilters.find(f => f.id === selectedFilter)
                                                const activePreset = hasUserFilters && existingFilter ? selectedFilter : null
                                                this.updateFilterPreset(callback, activePreset)
                                            });
                                        }, () => errorCallback && errorCallback())}
                                        onChange={(filters) => {
                                            const updateState = () => {
                                                this.setState({filters}, this.delayedViewRender);
                                            };

                                            if (Object.keys(this.state.filters).length === 0) {
                                                updateState();
                                            } else {
                                                if (selectedFilter) {
                                                    updateState()
                                                } else {
                                                    filters.users = filters.users.map(user => {
                                                        delete user.color
                                                        return user
                                                    })
                                                    this.props.actions.saveFilter(filters, updateState);
                                                }
                                            }
                                        }}
                                    />}
                                </Col>
                            </Panel>
                        </Col>
                        }

                        <Col id='scheduler' md={this.renderSchedulerGrid()}
                             style={{
                                 marginLeft: this.setCalendarMargin(),
                                 position: 'relative',
                                 paddingTop: smMobile && '30px',
                                 overflow: isMobile ? 'auto' : 'visible'
                             }}
                             className={`${!newSchedulerView && "mt10"} ${fullSizeFilterPanel && "pr-5"}`}
                             xs={newSchedulerView && this.state.calendarWidth}>
                            {!newSchedulerView && this.renderPresetInput()}
                            {this.renderBalanceContainer(balance)}
                            {filters &&
                            <FullCalendar
                                eventClassNames={this.state.isLoading ? "disabled pointer-events-disabled" : ""}
                                titleFormat= {{
                                    day: "2-digit",
                                    month: 'long',
                                    year: 'numeric'
                                }}
                                dayHeaderFormat={{
                                    weekday: 'short',
                                    month: '2-digit',
                                    day: '2-digit',
                                    omitCommas: true
                                }}
                                ref={this.calendarComponentRef}
                                fixedWeekCount={false}
                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin]}
                                initialView={periodType ? periodType : "dayGridMonth"}
                                slotDuration="00:30:00"
                                snapDuration="00:15:00"
                                slotLabelInterval="01:00"
                                contentHeight={window.innerHeight * heightProportion}
                                events={events.filter(e => !e.deleted && !e.hasCopy)}
                                eventSources={events.filter(e => !e.deleted && !e.hasCopy)}
                                defaultTimedEventDuration={eventTime ? eventTime : '01:00'}
                                scrollTime={start_time}
                                droppable={true}
                                selectable={true}
                                slotEventOverlap={false}
                                editable={!this.state.isLoading}
                                timeZone={'local'}
                                initialDate={this.state.midDate.format()}
                                eventClick={this.handleClick}
                                eventResize={this.handleChange}
                                eventDrop={this.handleChange}
                                eventsSet={this.handleEvents}
                                dayCellContent={(arg) =>
                                    arg.view.type === 'dayGridMonth' &&
                                    <DayHeader arg={arg} birthdays={birthdays} truckServices={truckServices} holidays={filteredHolidays}
                                               employees={employees} startDates={startDates}
                                    />
                                }
                                dayHeaderContent={(arg) =>
                                    ["timeGridWeek", "timeGridDay", "listWeek"].includes(arg.view.type) &&
                                    <DayHeader arg={arg} birthdays={birthdays} truckServices={truckServices} holidays={filteredHolidays}
                                               employees={employees} startDates={startDates}
                                    />
                                }
                                select={values => {
                                    this.setState({
                                        start_date: values.start,
                                        end_date: values.end,
                                    })
                                }}
                                dayMaxEventRows={true}
                                views={{
                                    dayGrid: {
                                        dayMaxEventRows: 2
                                    },
                                    timeGrid: {
                                        dayMaxEventRows: 2
                                    }
                                }}
                                eventContent={(e) =>
                                    <EventContent event={e} filters={filters} employees={employees}/>
                                }
                                customButtons={{
                                    prev: {
                                        click: () => {
                                            this.updateDate(moment(this.state.midDate).subtract(1, this.period[this.state.view.type]));
                                        }
                                    },
                                    next: {
                                        click: () => {
                                            this.updateDate(moment(this.state.midDate).add(1, this.period[this.state.view.type]));
                                        }
                                    },
                                    today: {
                                        text: 'today',
                                        click: () => {
                                            this.updateDate(moment())
                                        }
                                    },
                                    sync: {
                                        hint: 'Refresh',
                                        text: <span className="glyphicon glyphicon-refresh"/>,
                                        click: function () {
                                            if (client.gcal_authorized) {
                                                syncCalendar(client.id, reload);
                                            } else {
                                                reload();
                                            }
                                        }
                                    },
                                    calendar: {
                                        hint: 'Calendar',
                                        text: <span className="glyphicon glyphicon-calendar"/>,
                                        click: toggleCalendar
                                    },
                                    filterCalendar: {
                                        hint: 'Filter calendar',
                                        text: <span className="glyphicon glyphicon-search"/>,
                                        click: () => {
                                            this.setState({filterVisible: !this.state.filterVisible})
                                        }
                                    }
                                }}
                                headerToolbar={{
                                    left: `prev,next today${newSchedulerView ? ' calendar filterCalendar' : ''}`,
                                    center: 'title',
                                    right: 'sync,dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                }}
                                dayCellDidMount={(arg) => {
                                    if (arg.view.type === 'timeGridWeek' || arg.view.type === 'timeGridDay') {
                                        this.setState({view: arg.view}, () => this.delayedViewRender)
                                    }
                                }}
                                viewDidMount={(arg) => {
                                    this.setState({view: arg.view}, this.delayedViewRender);
                                }}
                                datesSet={(dateInfo) => {
                                    const differentStart = !moment(dateInfo.startStr).isSame(this.state.start);
                                    const differentEnd = !moment(dateInfo.endStr).isSame(this.state.end);
                                    if ((differentStart || differentEnd)) {
                                        this.setState({events: []});
                                        const {setUserCalendarState} = this.props.actions;
                                        calendarState.periodType = dateInfo.view.type;
                                        setUserCalendarState(calendarState, () => {
                                            this.setState({
                                                start: moment(dateInfo.startStr),
                                                end: moment(dateInfo.endStr),
                                                view: dateInfo.view,
                                                scroll: !isMobile,
                                                calendarState,
                                                periodType: calendarState.periodType
                                            })
                                        })
                                    }
                                }}
                                progressiveEventRendering={false}
                                drop={(dropInfo) => {
                                    if (dropInfo.draggedEl.dataset.id) {
                                        let workOrderId = parseInt(dropInfo.draggedEl.dataset.id);
                                        let woHours = workOrders.find(w => w.id === workOrderId)?.service_hours
                                        let event = {
                                            all_day: false,
                                            scheduler_event_type_resource: 'WorkOrder',
                                            scheduler_event_resource_id: workOrderId,
                                            date_from: moment(dropInfo.dateStr),
                                            employee: employees[0].value
                                        };
                                        if (moment(dropInfo.dateStr).hour() === 0) {
                                            event.date_from = event.date_from.hour(8);
                                        }

                                            if (client.hour_event_duration) {
                                                event.date_to = moment(event.date_from).add(1, 'hour');
                                            } else {
                                                if (woHours) {
                                                    woHours = convertFloatToHour(woHours)
                                                }

                                                const hours = woHours.toString().split(".")[0] * 60
                                                const minutes = woHours.toString().split(".")[1]
                                                const woServiceInMinutes = (+hours) + (+minutes)

                                                event.date_to = moment(event.date_from).add(woHours ? woServiceInMinutes : 60, "minutes")

                                            }
                                            this.handleClick(event);
                                        }
                                    }}
                                    eventDidMount={(event) => {
                                        event.el.setAttribute('event_id', event.event.extendedProps.event_id)
                                        window.dispatchEvent(new Event('resize'));
                                        this.calculateRowHeight(event.event.extendedProps.event_id);
                                    }}
                                />
                            }
                        </Col>
                        {!newSchedulerView &&
                            <Col className={fullSizeWOPanel && 'col-md-2 classic-right-panel-opened textCenter'}>
                                <Panel
                                    collapsible
                                    bsClass="ps-default-panel-wrap"
                                    defaultExpanded
                                >
                                    {fullSizeWOPanel &&
                                        <Col md={12} className={`${!fullSizeWOPanel && "no-right-padding"} mt10`}>
                                            <div
                                                className={`${width1400 ? "font14" : "font16"} classic-wo-filters-header`}>
                                      <span
                                          className={workOrders && workOrders.length === 0 && "mr-9"}>Work Orders:</span>
                                                <Link
                                                    className="pointer text-right"
                                                    onClick={() => {
                                                        this.setState({
                                                            workOrdersFilterModal: !workOrdersFilterModal,
                                                            oldWorkOrdersFilter: {...this.state.workOrdersFilter}
                                                        })
                                                    }}>
                                                    Filters<Glyphicon glyph="chevron-right"/>
                                                </Link>
                                            </div>
                                            <WorkOrders isMobile={isMobile} handleClick={this.handleClick}
                                                        setWorkOrder={this.setWorkOrder}
                                                        onWoClick={() => this.setState({scroll: false})}
                                                        workOrders={workOrders} calendar={this.calendarComponentRef}
                                                        removePsFromWo={this.props.actions.removePsFromWo}
                                                        loadWorkOrders={() => this.loadWorkOrders()}/>
                                        </Col>}
                                </Panel>
                            </Col>
                        }
                        <Col md={newSchedulerView ? 3 : 2} xs={newSchedulerView && this.state.WOWidth}>
                            {loaded &&
                                <Row>
                                    <WorkOrdersFilterModal
                                        closeButton={() => {
                                            this.setState(
                                                {
                                                    workOrdersFilterModal: !this.state.workOrdersFilterModal,
                                                    workOrdersFilter: {...this.state.oldWorkOrdersFilter}
                                                }, () => {
                                                    this.loadWorkOrders()
                                                });
                                        }}
                                        workOrdersLoaded={workOrdersLoaded}
                                        show={this.state.workOrdersFilterModal}
                                        onHide={() => {
                                            this.setState({workOrdersFilterModal: !this.state.workOrdersFilterModal})
                                        }}
                                        onChange={filter => {
                                            this.setState({
                                                workOrdersFilter: filter,
                                                workOrdersLoaded: false
                                            }, this.loadWorkOrders());
                                        }}
                                        filter={this.state.workOrdersFilter}
                                        workOrderSearchResults={this.state.workOrderSearchResults}
                                        onWoSearch={() => this.handleSearchWoResults('workOrder', 'workOrderSearchResults')}
                                        customerSearchResults={this.state.customerSearchResults}
                                        onCustomerSearch={() => this.handleSearchWoResults('customer', 'customerSearchResults')}
                                        citySearchResults={this.state.citySearchResults}
                                        onCitySearch={() => this.handleSearchWoResults('city', 'citySearchResults')}
                                        zipSearchResults={this.state.zipSearchResults}
                                        onZipSearch={() => this.handleSearchWoResults('zip', 'zipSearchResults')}

                                    />
                                    <Col md={12} className={isMobile && 'mt-20'}>
                                        {newSchedulerView &&
                                            <div>
                                                <div className="font16 heading-orders">
                                                    Work Orders:
                                                    <Link
                                                        className="pointer text-right big-mar-left"
                                                        onClick={() => {
                                                            this.setState({
                                                                workOrdersFilterModal: !workOrdersFilterModal,
                                                                oldWorkOrdersFilter: {...this.state.workOrdersFilter}
                                                            })
                                                        }}>
                                                        Filters<Glyphicon glyph="chevron-right"/>
                                                    </Link>
                                                </div>
                                                <WorkOrders isMobile={isMobile} handleClick={this.handleClick}
                                                            setWorkOrder={this.setWorkOrder}
                                                            onWoClick={() => this.setState({scroll: false})}
                                                            workOrders={workOrders} calendar={this.calendarComponentRef}
                                                            removePsFromWo={this.props.actions.removePsFromWo}
                                                            loadWorkOrders={() => this.loadWorkOrders()}
                                                />
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            }
                        </Col>
                        {
                            calendarVisible &&
                            <FormGroup id='scheduler-date-picker'>
                                <section className="datetime-picker--section">
                                    <Datetime open viewMode="days"
                                              value={midDate}
                                              onChange={midDate => this.updateDate(midDate)}
                                    />
                                </section>
                            </FormGroup>
                        }
                    </Row>
                    {this.state.emailDialogShow && <EmailDialog
                        onHide={this.emailDialogShow}
                        emailType={emails.options.emailType}
                        referenceId={emails.options.referenceId}
                        recipient={emails.options.recipient}
                        handleAction={emails.options.handleAction}
                        withFollowUpDate={emails.options.withFollowUpDate}
                        defaultEmail={emails.options.defaultEmail}
                        sendInBackground={true}
                    />}

                    {showModal && <SchedulerEventDialog
                        emailDialogShow={this.emailDialogShow}
                        handleClose={() => {
                            this.setState({showModal: false, scroll: false});
                            this.reload(false);
                        }}
                        event={event}
                        handleReoccuringEventChange={this.handleEventChange}
                    />}

                    {!!editedReoccurringEvent &&
                        <ReoccurringEventModal show={!!editedReoccurringEvent} event={editedReoccurringEvent}
                                               onHide={() => {
                                                   this.setState({editedReoccurringEvent: null})
                                               }}
                                               onChangeSingle={(e) => {
                                                   this.handleCopyEvent(e)
                                               }}
                                               onChangeAll={(e) => {
                                                   this.handleRescheduleAll(e)
                                               }}
                                               onChangeAllFuture={(e) => {
                                                   this.handleRescheduleAllFuture(e)
                                               }}
                        />}
                </Grid> : null
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        clientId: state.auth.client_id,
        emails: state.emails,
        showTaskDialog: state.userStatus.showTaskDialog,
        employee_id: state.auth.employee_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
